import {
  TypesActionsWarehouse,
  LIST_WAREHOUSE,
  SHOW_WAREHOUSE,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE,
  RESET_WAREHOUSES_STATE,
} from '../../actions/warehouse/WarehouseTypes';
import { WarehouseState } from '../../models/warehouse/WarehouseState';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: WarehouseState = {
  warehouses: generalResponse,
  warehouse: {
    _id: '',
    accountId: '',
    name: '',
    location: '',
    manager: '',
    createdAt: '',
    __v: 0,
  },
};

export default function warehouseReducer(
  state = initialState,
  action: TypesActionsWarehouse
) {
  switch (action.type) {
    case LIST_WAREHOUSE: {
      const warehouses = action.payload;
      return {
        ...state,
        warehouses,
      };
    }
    case SHOW_WAREHOUSE: {
      const warehouse = action.payload;
      return {
        ...state,
        warehouse,
      };
    }
    case UPDATE_WAREHOUSE: {
      const warehouse = action.payload;
      const index = state.warehouses.docs.findIndex(
        (todo) => todo._id === warehouse._id
      );
      const newArray = [...state.warehouses.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.warehouses;
      const warehouses = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        warehouses,
      };
    }
    case DELETE_WAREHOUSE: {
      const id = action.payload;
      return {
        ...state,
        warehouses: {
          docs: [...state.warehouses.docs.filter((e) => e._id !== id)],
        },
      };
    }
    case RESET_WAREHOUSES_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
