import {
  ADD_USER,
  LIST_USER,
  UPDATE_USER,
  RESET_USERS_STATE,
  TypesActionsUsers,
} from '../../actions/users/UsersTypesActions';
import { UserState } from '../../models/user/UserState';
import { UserInitialValues } from '../../../types/users/interfaces';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: UserState = {
  users: generalResponse,
  user: UserInitialValues,
};

export default function usersReducer(
  state = initialState,
  action: TypesActionsUsers
) {
  switch (action.type) {
    case LIST_USER: {
      const users = action.payload;
      return {
        ...state,
        users,
      };
    }
    case ADD_USER: {
      const user = action.payload;
      return {
        ...state,
        user,
      };
    }
    case UPDATE_USER: {
      const user = action.payload;
      const index = state.users.docs.findIndex((e) => e._id === user._id);
      const newArray = [...state.users.docs];
      newArray[index] = user;
      const { docs, ...rest } = state.users;
      const users = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        users,
      };
    }
    case RESET_USERS_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
