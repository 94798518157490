import { FormikProps } from 'formik';
import * as Yup from 'yup';

import { ResponseWarehouse, Warehouse } from './Warehouse';
import { UserAuth } from '../users/User';

export interface WarehouseMainProps {
  warehouses: ResponseWarehouse;
  listWarehouse: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deleteWarehouse: (
    form: {
      id: string;
      accountId: string;
    },
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
}

export interface WarehouseCreateProps {
  createWarehouse: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
}

export interface WarehouseEditProps {
  warehouse: Warehouse;
  showWarehouse: (
    id: string,
    accountId: string,
    callback: Function
  ) => Promise<void>;
  updateWarehouse: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
}

export interface WarehouseFormValues {
  id?: string;
  _id: string;
  location: string;
  name: string;
  manager: string;
  accountId?: string;
}
export const WarehouseInitialFormValues: WarehouseFormValues = {
  id: '',
  _id: '',
  name: '',
  location: '',
  manager: '',
  accountId: '',
};

export const WarehouseValidationSchema: Yup.SchemaOf<WarehouseFormValues> =
  Yup.object({
    id: Yup.string(),
    _id: Yup.string(),
    name: Yup.string().required('Este campo es requerido'),
    manager: Yup.string().required('Este campo es requerido'),
    location: Yup.string().required('Este campo es requerido'),
    accountId: Yup.string(),
  });

export const WarehouseInitialValues: Warehouse = {
  _id: '',
  accountId: '',
  name: '',
  manager: '',
  location: '',
  createdAt: '',
  __v: 0,
};
