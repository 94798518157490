import {
  Order,
  ResponseOrdersProps,
  OrdersList,
  ResponseOrderPayment,
  ResponseOrderInvoices,
  OrderInvoice,
} from '../../../types/orders/Orders';

import { TableBasic } from '../../../types/tables/Tables';

export const LIST_ORDERS = 'LIST_ORDERS';
export const LIST_ORDERS_CHECK_IN = 'LIST_ORDERS_CHECK_IN';
export const LIST_ORDERS_HISTORY = 'LIST_ORDERS_HISTORY';
export const ADD_ORDER = 'ADD_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const SET_PANEL = 'SET_PANEL';
export const ORDER_NOTIFICATIONS = 'ORDER_NOTIFICATIONS';
export const CLEAR_ORDER_NOTIFICATIONS = 'CLEAR_ORDER_NOTIFICATIONS';
export const ORDERS_TABLE = 'ORDERS_TABLE';
export const CLEAR_ORDERS_TABLE = 'CLEAR_ORDERS_TABLE';
export const ORDER_PAYMENTS = 'ORDER_PAYMENTS';
export const SET_INVOICE_NUMBER = 'SET_INVOICE_NUMBER';
export const LIST_ORDER_INVOICES = 'LIST_ORDER_INVOICES';
export const UPDATE_ORDER_INVOCE = 'UPDATE_ORDER_INVOICE';
export const RESET_ORDERS_STATE = 'RESET_ORDERS_STATE';

interface ActionListOrders {
  type: typeof LIST_ORDERS;
  payload: ResponseOrdersProps;
}

interface ActionListOrdersCheckIn {
  type: typeof LIST_ORDERS_CHECK_IN;
  payload: ResponseOrdersProps;
}

interface ActionListOrdersHistory {
  type: typeof LIST_ORDERS_HISTORY;
  payload: ResponseOrdersProps;
}

interface ActionAddOrders {
  type: typeof ADD_ORDER;
  payload: Order;
}

interface PanelOrders {
  type: typeof SET_PANEL;
  payload: Order[];
}

interface ActionDeleteOrders {
  type: typeof DELETE_ORDER;
  payload: string;
}

interface ActionUpdateOrders {
  type: typeof UPDATE_ORDER;
  payload: Order;
}

interface ActionOrderNotifications {
  type: typeof ORDER_NOTIFICATIONS;
  payload: OrdersList[];
}

interface ActionClearOrderNotifications {
  type: typeof CLEAR_ORDER_NOTIFICATIONS;
}

interface ActionOrdersTable {
  type: typeof ORDERS_TABLE;
  payload: { orders: Order[]; table: TableBasic };
}

interface ActionClearOrdersTable {
  type: typeof CLEAR_ORDERS_TABLE;
}

interface ActionOrderPayments {
  type: typeof ORDER_PAYMENTS;
  payload: ResponseOrderPayment;
}

interface ActionSetInvoiceNumber {
  type: typeof SET_INVOICE_NUMBER;
  payload: {
    invoiceNumber: string;
    dgtKey: string;
  };
}

interface ActionListOrderInvoices {
  type: typeof LIST_ORDER_INVOICES;
  payload: ResponseOrderInvoices;
}

interface ActionUpdateOrderInvoice {
  type: typeof UPDATE_ORDER_INVOCE;
  payload: OrderInvoice;
}

interface ActionResetOrdersState {
  type: typeof RESET_ORDERS_STATE;
}

export type TypesActionsOrders =
  | ActionListOrders
  | ActionListOrdersCheckIn
  | ActionListOrdersHistory
  | ActionAddOrders
  | ActionDeleteOrders
  | ActionUpdateOrders
  | PanelOrders
  | ActionOrderNotifications
  | ActionClearOrderNotifications
  | ActionOrdersTable
  | ActionOrderPayments
  | ActionSetInvoiceNumber
  | ActionListOrderInvoices
  | ActionUpdateOrderInvoice
  | ActionClearOrdersTable
  | ActionResetOrdersState;
