import { ResponsePayroll } from '../../../types/payroll/Payroll';

export const LIST_PAYROLLS = 'LIST_PAYROLLS';

export const RESET_PAYROLLS_STATE = 'RESET_PAYROLLS_STATE';

interface ActionListPayroll {
  type: typeof LIST_PAYROLLS;
  payload: ResponsePayroll;
}

interface ActionResetPayrollState {
  type: typeof RESET_PAYROLLS_STATE;
}

export type TypesActionsPayroll = ActionListPayroll | ActionResetPayrollState;
