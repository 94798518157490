import {
  ADD_ORDER,
  DELETE_ORDER,
  LIST_ORDERS,
  LIST_ORDERS_CHECK_IN,
  TypesActionsOrders,
  UPDATE_ORDER,
  SET_PANEL,
  ORDER_NOTIFICATIONS,
  CLEAR_ORDER_NOTIFICATIONS,
  ORDERS_TABLE,
  LIST_ORDERS_HISTORY,
  ORDER_PAYMENTS,
  SET_INVOICE_NUMBER,
  LIST_ORDER_INVOICES,
  UPDATE_ORDER_INVOCE,
  CLEAR_ORDERS_TABLE,
  RESET_ORDERS_STATE,
} from './OrdersTypes';
import {
  Order,
  ResponseOrdersProps,
  ChangeStatusFoodObject,
  OrdersList,
  ResponseOrderPayment,
  OrderInvoice,
  ResponseOrderInvoices,
  InvoiceChangeStatusForm,
} from '../../../types/orders/Orders';
import { OrdersDao } from '../../../api/orders/dao/orders.dao';
import { OrdersRepository } from '../../../api/orders/repository/orders.repository';
import { Alert } from 'react-native';
// import ToastifyAlert from 'src/app/shared/components/MultiplePorpuseAlert/ToastifyAlert';
import { AxiosResponse } from 'axios';
import { TableBasic } from '../../../types/tables/Tables';
import {
  ChangePaymentFoodStatus,
  PaymentMethod,
  RemoveFoodOrder,
} from '../../../types/orders/interfaces';
import {
  PaymentInvoiceDTO,
  SplitOrder,
  OrderDataDTO,
  PaymentMethodValues,
  MakeElectronicInvoiceDTO,
  ResendInvoiceDTO,
  DataPaymentInvoiceDTO,
} from '../../../types/checkIn/interface';
import {
  suprimeDuplicateElementsArray,
  formatDate,
  getFee,
} from '../../../utils/helpers';
import { Food } from '../../../types/foods/Food';
import { PaymentOrderStatus, StatusOrder } from '../../..//utils/enums';
import { ElectronicInvoice } from '../../../types/orders/oldInterfaces';

export function listOrders(payload: ResponseOrdersProps): TypesActionsOrders {
  return {
    type: LIST_ORDERS,
    payload,
  };
}

export function listOrdersCheckIn(
  payload: ResponseOrdersProps
): TypesActionsOrders {
  return {
    type: LIST_ORDERS_CHECK_IN,
    payload,
  };
}

export function listOrderPayments(
  payload: ResponseOrderPayment
): TypesActionsOrders {
  return {
    type: ORDER_PAYMENTS,
    payload,
  };
}

export function listOrderInvoices(
  payload: ResponseOrderInvoices
): TypesActionsOrders {
  return {
    type: LIST_ORDER_INVOICES,
    payload,
  };
}

export function listOrdersHistory(
  payload: ResponseOrdersProps
): TypesActionsOrders {
  return {
    type: LIST_ORDERS_HISTORY,
    payload,
  };
}

export function addNewOrder(Order: Order): TypesActionsOrders {
  return {
    type: ADD_ORDER,
    payload: Order,
  };
}
export function updateOrder(Order: Order): TypesActionsOrders {
  return {
    type: UPDATE_ORDER,
    payload: Order,
  };
}

export function deleteOrder(Order: string): TypesActionsOrders {
  return {
    type: DELETE_ORDER,
    payload: Order,
  };
}

export function panelOrder(Orders: Order[]): TypesActionsOrders {
  return {
    type: SET_PANEL,
    payload: Orders,
  };
}

export function listOrderNotifications(
  payload: OrdersList[]
): TypesActionsOrders {
  return {
    type: ORDER_NOTIFICATIONS,
    payload,
  };
}

export function clearOrderNotifications(): TypesActionsOrders {
  return { type: CLEAR_ORDER_NOTIFICATIONS };
}

export function OrdersTable(
  orders: Order[],
  table: TableBasic
): TypesActionsOrders {
  return { type: ORDERS_TABLE, payload: { orders, table } };
}

export function clearOrdersTable(): TypesActionsOrders {
  return { type: CLEAR_ORDERS_TABLE };
}

export function setInvoiceNumber(payload: {
  invoiceNumber: string;
  dgtKey: string;
}): TypesActionsOrders {
  return {
    type: SET_INVOICE_NUMBER,
    payload,
  };
}

export function updateOrderInvoice(payload: OrderInvoice): TypesActionsOrders {
  return {
    type: UPDATE_ORDER_INVOCE,
    payload,
  };
}

export function resetOrdersState(): TypesActionsOrders {
  return {
    type: RESET_ORDERS_STATE,
  };
}

export function ordersTableAsync(
  table: TableBasic,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const currentDate = new Date();
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      const nextDay = formatDate(nextDate, '');

      const prevDate = new Date(currentDate);
      prevDate.setDate(currentDate.getDate() - 1);
      const prevDay = formatDate(prevDate, '');

      const response: AxiosResponse<ResponseOrdersProps> = await OrdersDao.list(
        table.account,
        1,
        1000,
        null,
        `tableId=${table._id}&dateFrom=${prevDay}&dateTo=${nextDay}&paymentStatus=${PaymentOrderStatus.PENDING}&status=!${StatusOrder.CANCELLED}`
      );
      dispacth(OrdersTable(response.data.docs, table));
      callbackSuccess && callbackSuccess();
    } catch (error) {
      callbackError && callbackSuccess();
    }
  };
}

export function listOrdersAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    await OrdersDao.list(accountId, numberPage, limit, search, filter).then(
      (response: AxiosResponse<ResponseOrdersProps>) => {
        dispacth(listOrders(response.data));
      }
    );
  };
}

export function listOrdersCheckInAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    await OrdersDao.list(accountId, numberPage, limit, search, filter).then(
      (response: AxiosResponse<ResponseOrdersProps>) => {
        dispacth(listOrdersCheckIn(response.data));
      }
    );
  };
}

export function listOrderPaymentsAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    await OrdersDao.listOrderPayments(
      accountId,
      numberPage,
      limit,
      search,
      filter
    ).then((response: AxiosResponse<ResponseOrderPayment>) => {
      dispacth(listOrderPayments(response.data));
    });
  };
}

export function listOrderInvoicesAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    await OrdersDao.listOrderInvoices(
      accountId,
      numberPage,
      limit,
      search,
      filter
    ).then((response: AxiosResponse<ResponseOrderInvoices>) => {
      dispacth(listOrderInvoices(response.data));
    });
  };
}

export function listOrdersHistoryAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    await OrdersDao.list(accountId, numberPage, limit, search, filter).then(
      (response: AxiosResponse<ResponseOrdersProps>) => {
        dispacth(listOrdersHistory(response.data));
      }
    );
  };
}

export function deleteOrdersAsync(accountId: string, data: object) {
  return function (dispacth: Function) {
    OrdersRepository.delete(data).then(
      async (response: AxiosResponse<Order>) => {
        Alert.alert(
          'Hecho',
          `Orden eliminada con éxito`,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
        await OrdersDao.list(accountId, null, null, null, null).then(
          (response: AxiosResponse<ResponseOrdersProps>) => {
            dispacth(listOrders(response.data));
          }
        );
      }
    );
  };
}

export function createOrdersAsync(
  form: object,
  callback?: (order?: Order) => void,
  showSuccessAlert?: (value: boolean) => void,
  showErrorAlert?: (value: boolean) => void,
  asyncCallback?: Function
  // setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Order> = await OrdersRepository.create(
        form
      );
      dispacth(addNewOrder(response.data));
      showSuccessAlert && showSuccessAlert(true);
      asyncCallback && (await asyncCallback());
      callback && callback(response.data);
    } catch ({ request }) {
      showErrorAlert && showErrorAlert(true);
    }
  };
}

export function updateOrdersAsync(
  form: Order,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const order: AxiosResponse<Order> = await OrdersRepository.update(form);
      callbackSuccess && callbackSuccess();
      dispacth(updateOrder(order.data));
    } catch ({ request }) {}
  };
}

export function updateInvoiceStatusAsync(
  form: InvoiceChangeStatusForm,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const invoice: AxiosResponse<OrderInvoice> =
        await OrdersRepository.updateInvoiceStatus(form);
      callbackSuccess && callbackSuccess();
      dispacth(updateOrderInvoice(invoice.data));
    } catch ({ request }) {
      callbackError && callbackError();
    }
  };
}

export function createElectronicCreditNote(
  form: InvoiceChangeStatusForm,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const invoice: AxiosResponse<OrderInvoice> =
        await OrdersRepository.createElectronicCreditNote(form);
      callbackSuccess && callbackSuccess();
      dispacth(updateOrderInvoice(invoice.data));
    } catch ({ request }) {}
  };
}

export function updateOrdersPanelKitchenAsync(
  form: Order,
  callback?: Function,
  updateOrderDetail?: Function
) {
  return async function (dispacth: Function) {
    try {
      const order: AxiosResponse<Order> = await OrdersRepository.update(form);
      dispacth(updateOrder(order.data));
      updateOrderDetail && updateOrderDetail(order.data);
      callback && callback();
    } catch ({ request }) {}
  };
}

export function showOrdersAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    OrdersDao.show(id)
      .then((response: AxiosResponse<Order>) => {
        dispacth(addNewOrder(response.data));
      })
      .catch(({ request }) => {
        Alert.alert('Error', `${JSON.parse(request.response).message}`, [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ]);
        callback();
      });
  };
}

export function changeFoodStatusAsync(
  form: ChangeStatusFoodObject,
  callback: Function
) {
  return async function (dispacth: Function) {
    try {
      const order: AxiosResponse<Order> =
        await OrdersRepository.changeFoodStatus(form);
      dispacth(updateOrder(order.data));
      Alert.alert(
        'Hecho',
        `Orden nro ${order.data.code} actualizada con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ]
      );
    } catch ({ request }) {
      Alert.alert('Error', `${JSON.parse(request.response).message}`, [
        {
          text: 'OK',
          style: 'cancel',
          onPress: () => {},
        },
      ]);
    }
  };
}

export function changeFoodStatusPanelKitchenAsync(
  form: ChangeStatusFoodObject,
  callback?: Function,
  updateOrderDetail?: Function
) {
  return async function (dispacth: Function) {
    try {
      const order: AxiosResponse<Order> =
        await OrdersRepository.changeFoodStatus(form);
      dispacth(updateOrder(order.data));
      updateOrderDetail && updateOrderDetail(order.data);
      callback && callback();
    } catch ({ request }) {}
  };
}

export function completeElectronicInvoiceAsync(id: string, callback: Function) {
  return async function (dispatch: Function) {
    try {
      const order: AxiosResponse<Order> =
        await OrdersRepository.completeElectronicInvoice(id);
      dispatch(updateOrder(order.data));
      Alert.alert('Hecho', 'Se ha generado la factura con éxito.', [
        {
          text: 'OK',
          style: 'cancel',
          onPress: () => {
            callback();
          },
        },
      ]);
    } catch ({ request }) {
      Alert.alert('Error', `${JSON.parse(request.response).message}`, [
        {
          text: 'OK',
          style: 'cancel',
          onPress: () => {},
        },
      ]);
    }
  };
}
export function getOrdersPanelAsync() {
  return async function (dispatch: Function) {
    try {
      const orders: AxiosResponse<Order[]> = await OrdersDao.panel();
      dispatch(panelOrder(orders.data));
    } catch ({ request }) {
      Alert.alert('Error', `${JSON.parse(request.response).message}`, [
        {
          text: 'OK',
          style: 'cancel',
          onPress: () => {},
        },
      ]);
    }
  };
}

export async function getOrdersExcel(
  accountId: string,
  search: string | null,
  filter: string | null
) {
  try {
    return await OrdersDao.getExcel(accountId, search, filter);
  } catch ({ request }) {
    Alert.alert('Error', `${JSON.parse(request.response).message}`, [
      {
        text: 'OK',
        style: 'cancel',
        onPress: () => {},
      },
    ]);
  }
}

export function changePaymentFoodStatusAsync(
  accountId: string,
  data: SplitOrder[],
  table: TableBasic,
  typePayment: string,
  paymentMethod: PaymentMethod,
  cashRegisterId: string,
  cashRegisterMovementOpenId: string,
  bagPrice: number,
  generalDiscountPercent: number,
  electronicInvoice: ElectronicInvoice,
  callback?: (length: number) => void,
  callbackError?: (message: string) => void,
  callbackExtra?: Function
) {
  return async function (dispacth: Function) {
    const billID = new Date().getTime();
    let IDs = data?.map((e) => e.orderId);
    IDs = suprimeDuplicateElementsArray(IDs);
    const arr: { orderId: string; foods: Food[] }[] = [];
    IDs?.forEach((e) => {
      const aux: Food[] = [];
      data?.forEach((d) => {
        if (e === d.orderId) {
          aux.push(d.food);
        }
      });
      const obj = { orderId: e, foods: aux };
      arr.push(obj);
    });

    const response: Array<AxiosResponse<OrderInvoice>> = [];
    for (let index = 0; index < arr.length; index++) {
      const e = arr[index];
      try {
        const subTotal = e.foods?.reduce((prev, val) => {
          return prev + val.subTotal;
        }, 0);
        const discountFee = e.foods?.reduce((prev, val) => {
          return prev + val.discountFee;
        }, 0);
        const otherFee = e.foods?.reduce((prev, val) => {
          return prev + val.otherFee;
        }, 0);
        const auxTotal = subTotal - discountFee + otherFee;
        const generalDiscountPrice = getFee(auxTotal, generalDiscountPercent);
        const data = {
          id: e.orderId,
          foodTimestampId: e.foods?.map((f) => String(f.timeStampId)),
          billID: String(billID),
          tableID: table,
          paymentMethod,
          total: e.foods?.reduce((prev, val) => {
            return prev + val.total;
          }, 0),
          cashRegisterId,
          cashRegisterMovementOpenId,
          bagPrice,
          generalDiscountPrice,
          typePayment,
          electronicInvoice,
        };
        const aux: AxiosResponse<OrderInvoice> =
          await OrdersRepository.changePaymentFoodStatus(data);
        response.push(aux);
      } catch (error) {
        console.log(error);
        callbackError &&
          callbackError(
            'Error al procesar el pago. Quedan productos pendientes por pagar'
          );
      }
    }

    callbackExtra && callbackExtra();

    const paidOrders: OrderInvoice[] = [];
    response?.forEach((r) => {
      const find = paidOrders?.find((e) => e._id === r.data._id);
      if (!find) {
        paidOrders.push(r.data);
      } else {
        const index = paidOrders?.findIndex((e) => e._id === find._id);
        paidOrders[index] = find;
      }
    });
    let invoiceNumber = '';
    let dgtKey = '';
    if (response.length) {
      invoiceNumber = response[response.length - 1].data.invoiceNumber;
      dgtKey = response[response.length - 1].data.dgtKey ?? '';
    }
    const today = formatDate(new Date(), '');
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    const nextDay = formatDate(nextDate, '');
    const prevDate = new Date(currentDate);
    prevDate.setDate(currentDate.getDate() - 1);
    const prevDay = formatDate(prevDate, '');

    const orders: AxiosResponse<ResponseOrdersProps> = await OrdersDao.list(
      accountId,
      0,
      1000,
      null,
      `tableId=${table._id}&dateFrom=${prevDay}&dateTo=${nextDay}&paymentStatus=${PaymentOrderStatus.PENDING}`
    );
    const auxOrders = orders.data.docs?.filter(
      (e) => e.status !== StatusOrder.CANCELLED
    );
    auxOrders.forEach((e) => {
      const aux = [...e.foods];
      e.foods = aux?.filter(
        (f) =>
          f.paymentStatus !== PaymentOrderStatus.CANCELLED &&
          f.orderStatus !== StatusOrder.DELETED
      );
    });
    dispacth(setInvoiceNumber({ invoiceNumber, dgtKey }));
    dispacth(OrdersTable(auxOrders, table));
    paidOrders.length && callback && callback(orders.data.docs.length);
  };
}

export function multiPaymentAsync(
  accountId: string,
  data: SplitOrder[],
  table: TableBasic,
  typePayment: string,
  paymentMethod: PaymentMethodValues[],
  cashRegisterId: string,
  cashRegisterMovementOpenId: string,
  userPinId: string,
  bagPrice: number,
  generalDiscountPercent: number,
  generalDiscountPrice: number,
  electronicInvoice: ElectronicInvoice,
  tips: PaymentMethodValues,
  callback?: (invoiceId: string) => void,
  callbackError?: (message: string) => void,
  callbackExtra?: Function
) {
  return async function (dispacth: Function) {
    const billID = String(new Date().getTime());
    let IDs = data?.map((e) => e.orderId);
    IDs = suprimeDuplicateElementsArray(IDs);
    const arr: { orderId: string; foods: Food[] }[] = [];
    IDs?.forEach((e) => {
      const aux: Food[] = [];
      data?.forEach((d) => {
        if (e === d.orderId) {
          aux.push(d.food);
        }
      });
      const obj = { orderId: e, foods: aux };
      arr.push(obj);
    });

    const newBagPrice = bagPrice / arr.length;

    const orderData: OrderDataDTO[] = [];

    let total = 0;
    let discountFeeTotal = 0;
    let serviceFeeTotal = 0;

    for (const element of arr) {
      // const subTotal = element.foods?.reduce((prev, val) => {
      //   return prev + val.subTotal;
      // }, 0);
      // const discountFee = element.foods?.reduce((prev, val) => {
      //   return prev + val.discountFee;
      // }, 0);
      // const otherFee = element.foods?.reduce((prev, val) => {
      //   return prev + val.otherFee;
      // }, 0);
      // const serviceFee = element.foods?.reduce((prev, val) => {
      //   return prev + val.serviceFeeToPay;
      // }, 0);
      //TODO: Pequeño refactor para hacer más eficiente estos calculos, y no tener que recorrer el array cada vez
      let subTotal = 0;
      let discountFee = 0;
      let otherFee = 0;
      let serviceFee = 0;
      let discountFeeToPay = 0;
      let serviceFeeToPay = 0;
      element.foods?.forEach((e) => {
        subTotal += e.subTotal;
        discountFee += e.discountFee;
        otherFee += e.otherFee;
        serviceFee += e.serviceFeeToPay;
        discountFeeToPay += e.discountFeeToPay;
        serviceFeeToPay += e.serviceFeeToPay;
      });
      discountFeeTotal += discountFeeToPay;
      serviceFeeTotal += serviceFeeToPay;
      const auxTotal = subTotal - discountFee + otherFee;
      const generalDiscountPrice = getFee(auxTotal, generalDiscountPercent);
      const data = {
        id: element.orderId,
        foodTimestampId: element.foods?.map((f) => {
          return {
            foodTimestampId: String(f.timeStampId),
            qty: f.quantityToPay,
          };
        }),
        total: element.foods?.reduce((prev, val) => {
          return prev + val.totalToPay;
        }, 0),
        bagPrice: newBagPrice,
        generalDiscountPrice,
        serviceFee,
        discountFee: discountFeeToPay,
      };
      orderData.push(data);
    }

    orderData?.forEach((e) => {
      total += e.total;
    });

    const payload = {
      orderData,
      billID,
      tableID: table,
      cashRegisterId,
      cashRegisterMovementOpenId,
      userPinId,
      typePayment,
      electronicInvoice,
      generalDiscountPrice,
      bagPrice,
      total,
      paymentMethod,
      accountId,
      tips,
      discountFee: discountFeeTotal,
      serviceFee: serviceFeeTotal,
    };
    try {
      const response: AxiosResponse<OrderInvoice> =
        await OrdersRepository.multiPayment(payload);
      if (JSON.stringify(response.data) === '{}') {
        throw new Error(
          'Ha ocurrido un error al procesar el pago, por favor intente nuevamente'
        );
      }
      callbackExtra && callbackExtra();
      let invoiceNumber = '';
      let dgtKey = '';
      invoiceNumber = response.data.invoiceNumber;
      dgtKey = response.data.dgtKey ?? '';
      const currentDate = new Date();
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      // const nextDay = formatDate(nextDate, '');
      const prevDate = new Date(currentDate);
      prevDate.setDate(currentDate.getDate() - 1);
      // const prevDay = formatDate(prevDate, '');
      // const orders: AxiosResponse<ResponseOrdersProps> = await OrdersDao.list(
      //   accountId,
      //   0,
      //   1000,
      //   null,
      //   `tableId=${table._id}&dateFrom=${prevDay}&dateTo=${nextDay}&paymentStatus=${PaymentOrderStatus.PENDING}&status=!${StatusOrder.CANCELLED}`
      // );
      // // orders.data.docs.forEach((e) => {
      // //   const aux = [...e.foods];
      // //   e.foods = aux?.filter(
      // //     (f) =>
      // //       f.paymentStatus !== PaymentOrderStatus.CANCELLED &&
      // //       f.orderStatus !== StatusOrder.DELETED
      // //   );
      // // });
      // dispacth(OrdersTable(orders.data.docs, table));
      dispacth(setInvoiceNumber({ invoiceNumber, dgtKey }));
      callback && callback(response.data._id);
    } catch (error) {
      callbackError &&
        callbackError('Error al procesar el pago, apertura Inactiva');
    }
    callbackExtra && callbackExtra();
  };
}

export function removeFoodAsync(
  data: RemoveFoodOrder,
  callbackSuccess?: (orders: Order[]) => void,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Order> = await OrdersRepository.removeFood(
        data
      );
      const today = formatDate(new Date(), '');
      const currentDate = new Date();
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      const nextDay = formatDate(nextDate, '');
      const prevDate = new Date(currentDate);
      prevDate.setDate(currentDate.getDate() - 1);
      const prevDay = formatDate(prevDate, '');
      const orders: AxiosResponse<ResponseOrdersProps> = await OrdersDao.list(
        response.data.account._id,
        0,
        1000,
        null,
        `tableId=${response.data.tableId._id}&dateFrom=${prevDay}&dateTo=${nextDay}&paymentStatus=${PaymentOrderStatus.PENDING}`
      );
      const auxOrders = orders.data.docs?.filter(
        (e) => e.status !== StatusOrder.CANCELLED
      );
      auxOrders.forEach((e) => {
        const aux = [...e.foods];
        e.foods = aux?.filter(
          (f) =>
            f.paymentStatus !== PaymentOrderStatus.CANCELLED &&
            f.orderStatus !== StatusOrder.DELETED
        );
      });
      dispacth(OrdersTable(auxOrders, response.data.tableId));
      callbackSuccess && callbackSuccess(orders.data.docs);
    } catch (error) {
      callbackError && callbackError();
    }
  };
}

export function invoicePayAsync(
  data: PaymentInvoiceDTO,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<{
        message: string;
        invoices: OrderInvoice[];
      }> = await OrdersRepository.invoicePay(data);
      response.data.invoices?.forEach((e) => {
        dispacth(updateOrderInvoice(e));
      });
      callbackSuccess && callbackSuccess();
    } catch (error) {
      callbackError && callbackError();
    }
  };
}

export async function getInvoiceExcel(
  accountId: string,
  search: string | null,
  filter: string | null
) {
  try {
    return await OrdersDao.getExcelInvoice(accountId, search, filter);
  } catch ({ request }) {
    Alert.alert('Error', `${JSON.parse(request.response).message}`, [
      {
        text: 'OK',
        style: 'cancel',
        onPress: () => {},
      },
    ]);
  }
}

export function makeElectronicInvoiceAsync(
  form: MakeElectronicInvoiceDTO,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const invoice: AxiosResponse<OrderInvoice> =
        await OrdersRepository.makeElectronicInvoice(form);
      callbackSuccess && callbackSuccess();
      dispacth(updateOrderInvoice(invoice.data));
    } catch ({ request }) {
      callbackError && callbackError();
    }
  };
}

export function resendInvoiceAsync(
  data: ResendInvoiceDTO,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function () {
    try {
      await OrdersRepository.resendElectronicInvoice(data);
      callbackSuccess && callbackSuccess();
    } catch (error) {
      callbackError && callbackError();
    }
  };
}

export function changePaymentMethodAsync(
  data: DataPaymentInvoiceDTO,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response = await OrdersRepository.changePaymentMethod(data);
      dispacth(updateOrderInvoice(response.data));
      callbackSuccess && callbackSuccess();
    } catch (error) {
      callbackError && callbackError();
    }
  };
}

export function sendTicketAsync(
  data: ResendInvoiceDTO,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function () {
    try {
      const response: AxiosResponse<OrderInvoice> =
        await OrdersRepository.sendTicket(data);
      callbackSuccess && callbackSuccess();
    } catch (error) {
      callbackError && callbackError();
    }
  };
}
