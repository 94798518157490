import {
  TypesActionsFoodComplements,
  LIST_FOOD_COMPLEMENTS,
  DELETE_FOOD_COMPLEMENT,
  ADD_FOOD_COMPLEMENT,
  UPDATE_FOOD_COMPLEMENT,
  GET_LIST_FOOD_COMPLEMENT,
  RESET_FOOD_COMPLEMENTS_STATE,
} from '../../actions/foodComplements/FoodComplementsTypes';
import { FoodComplementsState } from '../../models/foodComplements/FoodComplementsState';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: FoodComplementsState = {
  foodComplements: generalResponse,
  foodComplement: {
    _id: '',
    name: '',
    description: '',
    status: '',
    price: 0,
    account: '',
  },
  foodComplementsList: [],
};

export default function foodComplementsReducer(
  state = initialState,
  action: TypesActionsFoodComplements
) {
  switch (action.type) {
    case GET_LIST_FOOD_COMPLEMENT: {
      const foodComplementsList = action.payload;
      return {
        ...state,
        foodComplementsList,
      };
    }
    case LIST_FOOD_COMPLEMENTS: {
      const foodComplements = action.payload;
      return {
        ...state,
        foodComplements: foodComplements,
      };
    }
    case DELETE_FOOD_COMPLEMENT: {
      const foodComplement = action.payload;
      return {
        ...state,
        foodComplements: {
          docs: [
            ...state.foodComplements.docs.filter(
              (e) => e._id !== foodComplement
            ),
          ],
        },
      };
    }
    case UPDATE_FOOD_COMPLEMENT: {
      const foodComplement = action.payload;
      const index = state.foodComplements.docs.findIndex(
        (todo) => todo._id === foodComplement._id
      );
      const newArray = [...state.foodComplements.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.foodComplements;
      const foodComplements = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state, //copying the orignal state
        foodComplements,
      };
    }
    case ADD_FOOD_COMPLEMENT: {
      const foodComplement = action.payload;
      return {
        ...state,
        foodComplement,
      };
    }
    case RESET_FOOD_COMPLEMENTS_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
