import * as Yup from 'yup';

import {
  CashRegisterMovement,
  ResponseCashMovement,
  ResponseCashRegisterMovement,
} from './CashRegisterMovement';
import { UserAuth } from '../users/User';
import { CashMovementType, CashRegisterType } from '../../utils/enums';

export interface OpenCashRegisterFormValues {
  amountCol: number;
  amountUsd: number;
}

export const openCashRegisterInitialValues: OpenCashRegisterFormValues = {
  amountCol: 0,
  amountUsd: 0,
};

export const openCashRegisterValidationSchema: Yup.SchemaOf<OpenCashRegisterFormValues> =
  Yup.object({
    amountCol: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    amountUsd: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
  });

export interface OpenCashRegisterProps {
  user: UserAuth;
  createCashRegisterMovement: (
    form: object,
    callbackSuccess: (id: string) => void,
    callbackError: (message?: string) => void
  ) => Promise<void>;
  updateUser: (user: UserAuth) => Promise<void>;
}

export interface CreateCashMovementProps {
  user: UserAuth;
  createCashMovement: (
    form: object,
    callbackSuccess: () => void,
    callbackError: () => void
  ) => Promise<void>;
}

export interface HistoryCashRegisterProps {
  listCashRegisterMovements: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  cashRegisterMovements: ResponseCashRegisterMovement;
  user: UserAuth;
}

export interface CashMovementsProps {
  listCashMovements: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  cashMovements: ResponseCashMovement;
  user: UserAuth;
}

export interface CloseCashRegisterProps {
  listCashRegisterMovements: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  cashRegisterMovements: ResponseCashRegisterMovement;
  user: UserAuth;
}

export interface CashRegisterEvidence {
  cash: number;
  cards: number;
  transfer: number;
  pendingPaid: number;
  total: number;
}

export interface FormValues {
  accountId: string;
  cashRegisterId: string;
  dollarAmount: number;
  amount: number;
  type: string;
  cashRegisterMovementReferenceId?: string;
  orders?: string[];
  cashRegisterName?: string;
  // cashRegisterEvidence?: CashRegisterEvidence
  cashRegisterEvidenceCash: number;
  cashRegisterEvidenceCards: number;
  cashRegisterEvidenceTransfer: number;
  cashRegisterEvidencePendingPaid: number;
  cashRegisterEvidenceTotal: number;
  cashForDeposit?: number;
  cashOnClosing?: number;
  userPinId: string;
}

export const initialValues: FormValues = {
  accountId: '',
  cashRegisterId: '',
  amount: 0,
  type: CashRegisterType.OPEN,
  cashRegisterMovementReferenceId: '',
  orders: [],
  dollarAmount: 0,
  cashRegisterName: '',
  // cashRegisterEvidence: {
  //   cash: 0,
  //   cards: 0,
  //   transfer: 0,
  //   pendingPaid: 0,
  //   total: 0,
  // },
  cashRegisterEvidenceCash: 0,
  cashRegisterEvidenceCards: 0,
  cashRegisterEvidenceTransfer: 0,
  cashRegisterEvidencePendingPaid: 0,
  cashRegisterEvidenceTotal: 0,
  cashForDeposit: 0,
  cashOnClosing: 0,
  userPinId: '',
};

export const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  accountId: Yup.string(),
  cashRegisterId: Yup.string(),
  amount: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .test('test amount', 'Debe ser una cantidad positiva', (value) =>
      value < 0 ? false : true
    )
    .required('Este campo es requerido'),
  type: Yup.string(),
  dollarAmount: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .test('test amount', 'Debe ser una cantidad positiva', (value) =>
      value < 0 ? false : true
    )
    .required('Este campo es requerido'),

  cashRegisterMovementReferenceId: Yup.string(),
  orders: Yup.array(Yup.string()).when('type', (type: string) => {
    if (type === CashRegisterType.CLOSE) {
      return Yup.array(Yup.string())
        .required('Este campo es requerido')
        .min(
          1,
          'Debe haber realizado al menos un movimiento para crear un cierre'
        );
    } else {
      return Yup.array(Yup.string());
    }
  }),
  cashRegisterName: Yup.string(),
  cashRegisterEvidenceCash: Yup.number().when('type', (type: string) => {
    if (type === CashRegisterType.CLOSE) {
      return Yup.number()
        .typeError('Debe ser una cantidad válida')
        .required('Este campo es requerido');
    } else {
      return Yup.number().nullable();
    }
  }),
  cashRegisterEvidenceCards: Yup.number().when('type', (type: string) => {
    if (type === CashRegisterType.CLOSE) {
      return Yup.number()
        .typeError('Debe ser una cantidad válida')
        .required('Este campo es requerido');
    } else {
      return Yup.number().nullable();
    }
  }),
  cashRegisterEvidenceTransfer: Yup.number().when('type', (type: string) => {
    if (type === CashRegisterType.CLOSE) {
      return Yup.number()
        .typeError('Debe ser una cantidad válida')
        .required('Este campo es requerido');
    } else {
      return Yup.number().nullable();
    }
  }),
  cashRegisterEvidencePendingPaid: Yup.number().when('type', (type: string) => {
    if (type === CashRegisterType.CLOSE) {
      return Yup.number()
        .typeError('Debe ser una cantidad válida')
        .required('Este campo es requerido');
    } else {
      return Yup.number().nullable();
    }
  }),
  cashRegisterEvidenceTotal: Yup.number().when('type', (type: string) => {
    if (type === CashRegisterType.CLOSE) {
      return Yup.number()
        .typeError('Debe ser una cantidad válida')
        .required('Este campo es requerido');
    } else {
      return Yup.number().nullable();
    }
  }),
  cashForDeposit: Yup.number().when('type', (type: string) => {
    if (type === CashRegisterType.CLOSE) {
      return Yup.number()
        .typeError('Debe ser un cantidad válida')
        .required('Este campo es requerido');
    } else {
      return Yup.number().nullable();
    }
  }),
  cashOnClosing: Yup.number().when('type', (type: string) => {
    if (type === CashRegisterType.CLOSE) {
      return Yup.number()
        .typeError('Debe ser un cantidad válida')
        .required('Este campo es requerido');
    } else {
      return Yup.number().nullable();
    }
  }),
  userPinId: Yup.string(),
});

export const cashRegisterMovementInitialValues: CashRegisterMovement = {
  _id: '',
  accountId: '',
  createdAt: '',
  deletedAt: null,
  amount: 0,
  cashRegisterId: '',
  type: '',
  status: '',
};

export interface CashMovementFormValues {
  accountId: string;
  cashRegisterId: string;
  cashRegisterMovementReferenceId: string;
  amount: number;
  type: string;
  notes: string;
  concept: string;
  currency: string;
  userPinId: string;
}

export const cashMovementFormValues: CashMovementFormValues = {
  accountId: '',
  cashRegisterId: '',
  cashRegisterMovementReferenceId: '',
  amount: 0,
  type: CashMovementType.IN,
  notes: '',
  concept: '',
  currency: 'CRC',
  userPinId: '',
};

export const cashMovementValidtionSchema: Yup.SchemaOf<CashMovementFormValues> =
  Yup.object({
    accountId: Yup.string(),
    cashRegisterId: Yup.string(),
    cashRegisterMovementReferenceId: Yup.string(),
    amount: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      // .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
      //   value <= 0 ? false : true
      // )
      .required('Este campo es requerido'),
    notes: Yup.string().required('Este campo es requerido'),
    concept: Yup.string().required('Este campo es requerido'),
    currency: Yup.string(),
    type: Yup.string().required('Este campo es requerido'),
    userPinId: Yup.string(),
  });
