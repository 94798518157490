import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import { ResponsePayroll } from '../../../types/payroll/Payroll';

export const PayrollDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponsePayroll>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    if (!page) {
      page = 0;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(
      `/payroll/list/attendance?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  getExcel: (
    accountId: string,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<Blob>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    return axiosIntance.get(
      `/payroll/list/attendance/export?accountId=${accountId}${query}${filter}`
    );
  },
};
