import * as Yup from 'yup';

import { UserAuth } from '../users/User';
import { ResponseSales, Sale, Product } from './Sales';
import { AccountSimplifiedPlanValues } from '../accounts/interfaces';
import { ResponseInventory } from '../inventory/Inventory';

export interface SalesScreenProps {
  user: UserAuth;
  sales: ResponseSales;
  listSale: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deleteSale: (
    form: {
      id: string;
      accountId: string;
    },
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
}

export interface CreateSaleProps {
  user: UserAuth;
  createSale: (
    form: object,
    callbackSuccess: (sale: Sale) => void,
    callbackError: Function
  ) => Promise<void>;
  inventories: ResponseInventory;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface EditSaleProps {
  user: UserAuth;
  sale: Sale;
  updateSale: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  getSale: (id: string, accountId: string, callback: Function) => Promise<void>;
  inventories: ResponseInventory;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface ProductFormValues extends Product {}

export interface SaleFormValues {
  id?: string;
  _id?: string;
  accountId: string;
  amount: number;
  description?: string;
  products: ProductFormValues[];
}

export const initialValues: SaleFormValues = {
  id: '',
  _id: '',
  accountId: '',
  amount: 0,
  description: '',
  products: [],
};

export const initialValuesProduct: ProductFormValues = {
  productId: '',
  name: '',
  qty: 1,
  unit: '',
  unitPrice: 0,
  total: 0,
  notes: '',
  category: '',
};

export const validationSchema: Yup.SchemaOf<SaleFormValues> = Yup.object({
  id: Yup.string(),
  _id: Yup.string(),
  accountId: Yup.string(),
  amount: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .test('Validacion de totalPrice', 'Debe ser mayor a 0', (value) =>
      value <= 0 ? false : true
    )
    .required('Este campo es requerido'),
  description: Yup.string(),
  products: Yup.array(
    Yup.object().shape({
      productId: Yup.string(),
      name: Yup.string(),
      qty: Yup.number(),
      unit: Yup.string(),
      unitPrice: Yup.number(),
      total: Yup.number(),
      notes: Yup.string(),
      category: Yup.string(),
    })
  ).min(1, 'Se requiere agregar al menos un producto'),
});

export const validationSchemaProduct: Yup.SchemaOf<ProductFormValues> =
  Yup.object({
    productId: Yup.string().required('Este campo es requerido'),
    name: Yup.string().required('Este campo es requerido'),
    category: Yup.string().required('Este campo es requerido'),
    qty: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    unit: Yup.string().required('Este campo es requerido'),
    unitPrice: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    total: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),

    notes: Yup.string(),
  });

export const SaleInitialValue: Sale = {
  _id: '',
  accountId: AccountSimplifiedPlanValues,
  code: 0,
  amount: 0,
  description: '',
  products: [],
  createdAt: '',
  deletedAt: '',
};
