import {
  ADD_USER,
  LIST_USER,
  UPDATE_USER,
  RESET_USERS_STATE,
  TypesActionsUsers,
} from './UsersTypesActions';
import {
  ADD_USER_AUTH,
  CHANGE_ACCOUNT,
  UPDATE_USER_AUTH,
  CLEAR_USER_AUTH,
  TypesActionsUser,
} from '../user/UserTypesActions';
import { UserDao } from '../../../api/user/dao/user.dao';
import { AxiosResponse } from 'axios';
import { ResponseUsersProps } from '../../../types/users/User';
import { UserRepository } from '../../../api/user/repository/user.repository';
import { Alert } from 'react-native';
import { UserAuth, User } from '../../../types/users/User';
import {
  ProfileData,
  FormValuesSecurity,
} from '../../../types/profileEdit/interfaces';
import { UsersRole } from '../../../utils/enums';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Auth } from '../../../types/auth/Auth';
import { Setting } from '../../../types/settings/Setting';

function listUsers(payload: ResponseUsersProps): TypesActionsUsers {
  return {
    type: LIST_USER,
    payload,
  };
}

function addNewUser(user: User): TypesActionsUsers {
  return {
    type: ADD_USER,
    payload: user,
  };
}

function updateUser(user: User): TypesActionsUsers {
  return {
    type: UPDATE_USER,
    payload: user,
  };
}

export function updateUserAuth(user: UserAuth): TypesActionsUser {
  return {
    type: UPDATE_USER_AUTH,
    payload: user,
  };
}

export function updateUserAuthStorage(user: UserAuth, callback?: Function) {
  return async function (dispacth: Function) {
    await AsyncStorage.setItem('user', JSON.stringify(user));
    dispacth(updateUserAuth(user));
    callback && callback();
  };
}

export function signIn(user: UserAuth, accessToken: string): TypesActionsUser {
  return {
    type: ADD_USER_AUTH,
    payload: user,
    accessToken,
  };
}

export function changeAccount(
  user: UserAuth,
  callback: Function
): TypesActionsUser {
  AsyncStorage.setItem('user', JSON.stringify(user)).then();
  callback();
  return {
    type: CHANGE_ACCOUNT,
    payload: user,
  };
}

export function clearUserAuth(): TypesActionsUser {
  return {
    type: CLEAR_USER_AUTH,
  };
}

export function resetUsersState(): TypesActionsUsers {
  return {
    type: RESET_USERS_STATE,
  };
}

export function listUsersAsync(
  currentPage: number,
  limit: number,
  search: string | null,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    // await UserDao.list(currentPage, limit, search).then(
    //   (response: AxiosResponse<ResponseUsersProps>) => {
    //     dispacth(listUsers(response.data));
    //   }
    // );
    try {
      const response = await UserDao.list(currentPage, limit, search);
      dispacth(listUsers(response.data));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch (error) {
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export async function CheckUserAsync(
  form: Object,
  callback: Function,
  callbackError: (message: string) => void
) {
  try {
    const response: AxiosResponse<Auth> = await UserDao.SignIn(form);
    callback(response.data);
  } catch ({ request }) {
    if (request) {
      callbackError(JSON.parse(request.response).message);
    } else {
      callbackError('Error al iniciar sesión');
    }
  }
}

export async function SignInAsync(
  user: any,
  callback: Function,
  checkAccount: Function,
  dispatch?: Function,
  setSettings?: Function
) {
  try {
    await AsyncStorage.removeItem('appTheme');
    await AsyncStorage.setItem('user', JSON.stringify(user));
    if (user.role === UsersRole.KITCHEN) {
      await AsyncStorage.setItem('appTheme', 'PureLightThemeKitchen');
    } else {
      await AsyncStorage.setItem('appTheme', 'PureLightTheme');
    }
    await checkAccount();
    dispatch && dispatch(signIn(user, user.accessToken));
    setSettings && (await setSettings());
    callback();
    // reloadWindow && reloadWindow();
  } catch (error) {
    await AsyncStorage.removeItem('user');
    await AsyncStorage.removeItem('notificationAssiatance');
    await AsyncStorage.removeItem('notificationOrders');
    await AsyncStorage.removeItem('savedOrder');
    await AsyncStorage.removeItem('P');
    return Alert.alert(
      'Error',
      'Ha ocurrido un error',
      [
        {
          text: 'OK',
          style: 'cancel',
          onPress: () => {},
        },
      ],
      { cancelable: false }
    );
  }
}

export function showUserAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    UserDao.show(id)
      .then((response: AxiosResponse<User>) => {
        dispacth(addNewUser(response.data));
      })
      .catch(({ request }) => {
        Alert.alert(
          'Error',
          `${JSON.parse(request.response).message}`,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
        callback();
      });
  };
}

export function updateUsersAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<User> = await UserRepository.update(form);
      dispacth(updateUser(response.data));
      Alert.alert(
        'Completado',
        `Usuario "${response.data.firstName} ${response.data.lastName}" actualizado con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
    } catch ({ request }) {
      Alert.alert(
        'Error',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
      setValidate(true);
    }
  };
}

export function recoveryPasswordStep1(mail: string) {
  return function () {
    UserRepository.recoveryPasswordStep1(mail)
      .then(() => {
        return Alert.alert(
          'Error',
          `Email enviado con éxito`,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
      })
      .catch(({ request }) => {
        return Alert.alert(
          'Error',
          `${JSON.parse(request.response).message}`,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
      });
  };
}

export function createUsersAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const user: AxiosResponse<User> = await UserRepository.create(form);
      dispacth(addNewUser(user.data));
      Alert.alert(
        'Completado',
        `Usuario "${user.data.firstName} ${user.data.lastName}" creado con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
    } catch ({ request }) {
      Alert.alert(
        'Completado',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
      setValidate(true);
    }
  };
}

export function deleteUserAsync(data: object) {
  return function (dispacth: Function) {
    UserRepository.delete(data).then(async (response: AxiosResponse<User>) => {
      Alert.alert(
        'Completado',
        `Usuario eliminado con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
      await UserDao.list(null, null, null).then(
        (response: AxiosResponse<ResponseUsersProps>) => {
          dispacth(listUsers(response.data));
        }
      );
    });
  };
}

export function changePasswordAsync(
  data: FormValuesSecurity,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function () {
    try {
      await UserRepository.changePassword(data);
      Alert.alert(
        'Completado',
        `Contraseña actualizada`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: (data) => {
              callback(data);
            },
          },
        ],
        { cancelable: false }
      );
    } catch ({ request }) {
      Alert.alert(
        'Completado',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: (data) => {},
          },
        ],
        { cancelable: false }
      );
      setValidate(true);
    }
  };
}

export function updateProfileAsync(
  form: ProfileData,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      await UserRepository.updateProfile(form);
      let tmpuser: UserAuth = JSON.parse(await AsyncStorage.getItem('user'));
      tmpuser.firstName = form.firstName;
      tmpuser.lastName = form.lastName;
      tmpuser.phone = form.phone;
      tmpuser.email = form.email;
      if (form.billTo) tmpuser.billTo = form.billTo;
      await AsyncStorage.setItem('user', JSON.stringify(tmpuser));
      dispacth(updateUserAuth(tmpuser));
      Alert.alert(
        'Completado',
        `Usuario actualizado con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
    } catch ({ request }) {
      Alert.alert(
        'Completado',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
      setValidate(true);
    }
  };
}
