import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/ApiConfig';
import {
  CashRegisterMovement,
  ResponseCashRegisterMovement,
  ResponseClosingStep1,
  ResponseClosingStep2,
  ResponseCashMovement,
} from '../../../types/cashRegisterMovement/CashRegisterMovement';

export const CashRegisterMovementDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseCashRegisterMovement>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = 0;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(
      `/cashRegisterMovement/list?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  show: (
    id: string,
    accountId: string
  ): Promise<AxiosResponse<CashRegisterMovement>> => {
    return axiosIntance.get(`/cashRegister/${accountId}/${id}`);
  },
  closingStep1: (
    id: string,
    accountId: string
  ): Promise<AxiosResponse<ResponseClosingStep1>> => {
    return axiosIntance.get(
      `cashRegisterMovement/${accountId}/${id}/payments/totals`
    );
  },
  closingStep2: (
    id: string,
    accountId: string
  ): Promise<AxiosResponse<ResponseClosingStep2>> => {
    return axiosIntance.get(`cashRegisterMovement/${accountId}/${id}/payments`);
  },
  listCashMovements: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseCashMovement>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = 0;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(
      `/cashRegisterMovement/cashMovement?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  getCashRegisterMovementExcel: (
    accountId: string,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<Blob>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    return axiosIntance.get(
      `/cashRegisterMovement/excel?accountId=${accountId}${query}${filter}`
    );
  },
};
