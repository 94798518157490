import { DecreaseDao } from '../../../api/decrease/dao/decrease.dao';
import { DecreaseRepository } from '../../../api/decrease/repository/decrease.repository';
import {
  TypesActionsDecrease,
  LIST_DECREASE,
  SHOW_DECREASE,
  UPDATE_DECREASE,
  DELETE_DECREASE,
  RESET_DECREASES_STATE,
} from './DecreaseTypes';
import { ResponseDecrease, Decrease } from '../../../types/decrease/Decrease';

function listDecrease(payload: ResponseDecrease): TypesActionsDecrease {
  return {
    type: LIST_DECREASE,
    payload,
  };
}

function showDecrease(payload: Decrease): TypesActionsDecrease {
  return {
    type: SHOW_DECREASE,
    payload,
  };
}

function updateDecrease(payload: Decrease): TypesActionsDecrease {
  return {
    type: UPDATE_DECREASE,
    payload,
  };
}

function deleteDecrease(payload: string): TypesActionsDecrease {
  return {
    type: DELETE_DECREASE,
    payload,
  };
}

export function resetDecreasesState(): TypesActionsDecrease {
  return {
    type: RESET_DECREASES_STATE,
  };
}

export function listDecreaseAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await DecreaseDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listDecrease(response.data));
  };
}

export function showDecreaseAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await DecreaseDao.show(accountId, id);
      dispatch(showDecrease(response.data));
    } catch (request) {
      callback();
    }
  };
}

export function createDecreaseAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await DecreaseRepository.create(form);
      callbackSuccess();
      dispatch(showDecrease(response.data));
    } catch (request) {
      callbackError();
    }
  };
}

export function updateDecreaseAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await DecreaseRepository.update(form);
      dispatch(updateDecrease(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function deleteDecreaseAsync(
  form: {
    id: string;
    accountId: string;
  },
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      await DecreaseRepository.delete(form);
      dispatch(deleteDecrease(form.id));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}
