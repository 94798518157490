import * as Yup from 'yup';

export interface LoginFormValues {
  email: string;
  password: string;
}

export const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

export const validationSchema: Yup.SchemaOf<LoginFormValues> = Yup.object({
  email: Yup.string()
    .email('Debe ser un email válido')
    .required('Este campo es requerido'),
  password: Yup.string()
    .min(8, 'Las contraseña debe tener al menos 8 carácteres')
    .required('Este campo es requerido'),
});
