import {
  TypesActionsPayroll,
  LIST_PAYROLLS,
  RESET_PAYROLLS_STATE,
} from '../../../store/actions/payroll/PayrollTypes';
import { PayrollState } from '../../../store/models/payroll/PayrollState';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: PayrollState = {
  payrolls: generalResponse,
};

export default function payrollReducer(
  state = initialState,
  action: TypesActionsPayroll
) {
  switch (action.type) {
    case LIST_PAYROLLS: {
      const payrolls = action.payload;
      return {
        ...state,
        payrolls,
      };
    }
    case RESET_PAYROLLS_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
