import {
  DELETE_ACCOUNT,
  LIST_ACCOUNTS,
  ADD_ACCOUNT,
  RESET_ACCOUNTS_STATE,
  TypesActionsAccounts,
} from '../../actions/accounts/AccountsTypes';
import { AccountsState } from '../../models/accounts/AccountsState';
import { generalResponse } from '../../../types/common/interfaces';
import { AccountInitialValues } from '../../../types/accounts/interfaces';

const initialState: AccountsState = {
  accounts: generalResponse,
  account: AccountInitialValues,
};

export default function accountsReducer(
  state = initialState,
  action: TypesActionsAccounts
) {
  switch (action.type) {
    case LIST_ACCOUNTS: {
      const accounts = action.payload;
      return {
        ...state,
        accounts,
      };
    }
    case DELETE_ACCOUNT: {
      const account = action.payload;
      return {
        ...state,
        accounts: {
          docs: [...state.accounts.docs.filter((e) => e._id !== account)],
        },
      };
    }
    case ADD_ACCOUNT: {
      const account = action.payload;
      return {
        ...state,
        account,
      };
    }
    case RESET_ACCOUNTS_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
