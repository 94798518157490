import {
  TypesActionsInventory,
  LIST_INVENTORY,
  LIST_INVENTORY2,
  SHOW_INVENTORY,
  UPDATE_INVENTORY,
  DELETE_INVENTORY,
  LIST_INVENTORY_MOVEMENTS,
  RESET_INVENTORY_STATE,
} from './InventoryTypes';
import {
  ResponseInventory,
  Inventory,
  ResponseInventoryMovements,
} from '../../../types/inventory/Inventory';
import { inventoryDao } from '../../../api/inventory/dao/inventory.dao';
import { InventoryRepository } from '../../../api/inventory/repository/inventory.repository';

function listInventory(payload: ResponseInventory): TypesActionsInventory {
  return {
    type: LIST_INVENTORY,
    payload,
  };
}

function listInventoryMovements(
  payload: ResponseInventoryMovements
): TypesActionsInventory {
  return {
    type: LIST_INVENTORY_MOVEMENTS,
    payload,
  };
}

function listInventory2(payload: ResponseInventory): TypesActionsInventory {
  return {
    type: LIST_INVENTORY2,
    payload,
  };
}

function showInventory(payload: Inventory): TypesActionsInventory {
  return {
    type: SHOW_INVENTORY,
    payload,
  };
}

function updateInventory(payload: Inventory): TypesActionsInventory {
  return {
    type: UPDATE_INVENTORY,
    payload,
  };
}

function deleteInventory(payload: string): TypesActionsInventory {
  return {
    type: DELETE_INVENTORY,
    payload,
  };
}

export function resetInventoryState(): TypesActionsInventory {
  return {
    type: RESET_INVENTORY_STATE,
  };
}

export function listInventoryAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await inventoryDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listInventory(response.data));
  };
}

export function listInventoryMovementsAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
  // productId?: string[]
) {
  return async function (dispacth: Function) {
    let id: string = null;
    // if (productId && productId.length) {
    //   id = productId[0];
    // }
    const response = await inventoryDao.listMovements(
      accountId,
      numberPage,
      limit,
      search,
      filter
      // id
    );
    dispacth(listInventoryMovements(response.data));
  };
}

export function listInventoryAsync2(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await inventoryDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listInventory2(response.data));
  };
}

export function showInventoryAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await inventoryDao.show(id, accountId);
      dispatch(showInventory(response.data));
    } catch (error) {
      callback();
    }
  };
}

export function createInventoryAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await InventoryRepository.create(form);
      callbackSuccess();
      dispatch(showInventory(response.data));
    } catch (request) {
      callbackError();
    }
  };
}

export function updateInventoryAsync(
  form: object,
  callbackSuccess?: Function,
  callbackError?: (message?: string) => void
) {
  return async function (dispatch: Function) {
    try {
      const response = await InventoryRepository.update(form);

      dispatch(updateInventory(response.data));
      callbackSuccess && callbackSuccess();
    } catch ({ request }) {
      const aux = JSON.parse(request.response).message;
      let messaje = 'Error al editar el producto';
      if (
        aux &&
        typeof aux === 'string' &&
        aux ===
          'No se puede desactivar el producto, existe recetas asociadas a el'
      ) {
        messaje = aux;
      }
      callbackError && callbackError(messaje);
    }
  };
}

export function deleteInventoryAsync(
  form: {
    id: string;
    accountId: string;
  },
  callbackSuccess?: Function,
  callbackError?: (message?: string) => void
) {
  return async function (dispatch: Function) {
    try {
      await InventoryRepository.delete(form);
      dispatch(deleteInventory(form.id));
      callbackSuccess && callbackSuccess();
    } catch ({ request }) {
      let message = 'Ha ocurrido un error';
      const aux = JSON.parse(request.response).message;
      if (
        aux &&
        typeof aux === 'string' &&
        aux ===
          'No se puede eliminar el producto, existe recetas asociadas a el'
      ) {
        message = aux;
      }
      callbackError && callbackError(message);
    }
  };
}

export function createInventoryMovementAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await InventoryRepository.createMovement(form);
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export async function getInventoryExcel(
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return await inventoryDao.getFoodsExcelInventory(accountId, search, filter);
}
