import { Purchase, ResponsePurchase } from '../../../types/shopping/Shopping';

export const LIST_PURCHASE = 'LIST_PURCHASE';
export const SHOW_PURCHASE = 'SHOW_PURCHASE';
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const DELETE_PURCHASE = 'DELETE_PURCHASE';
export const GET_PURCHASES = 'GET_PURCHASES';
export const RESET_PURCHASES_STATE = 'RESET_PURCHASES_STATE';

interface ActionListPurchase {
  type: typeof LIST_PURCHASE;
  payload: ResponsePurchase;
}

interface ActionShowPurchase {
  type: typeof SHOW_PURCHASE;
  payload: Purchase;
}

interface ActionUpdatePurchase {
  type: typeof UPDATE_PURCHASE;
  payload: Purchase;
}

interface ActionDeletePurchase {
  type: typeof DELETE_PURCHASE;
  payload: string;
}

interface ActionGetPurchases {
  type: typeof GET_PURCHASES;
  payload: ResponsePurchase;
}

export interface ActionResetPurchaseState {
  type: typeof RESET_PURCHASES_STATE;
}

export type TypesActionsPurchase =
  | ActionListPurchase
  | ActionShowPurchase
  | ActionUpdatePurchase
  | ActionDeletePurchase
  | ActionGetPurchases
  | ActionResetPurchaseState;
