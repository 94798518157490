import { providerDao } from '../../../api/provider/dao/provider.dao';
import { ProviderRepository } from '../../../api/provider/repository/provider.repository';
import {
  TypesActionsProvider,
  LIST_PROVIDER,
  SHOW_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  RESET_PROVIDERS_STATE,
} from './ProviderTypes';
import { ResponseProvider, Provider } from '../../../types/provider/Provider';

function listProvider(payload: ResponseProvider): TypesActionsProvider {
  return {
    type: LIST_PROVIDER,
    payload,
  };
}

function showProvider(payload: Provider): TypesActionsProvider {
  return {
    type: SHOW_PROVIDER,
    payload,
  };
}

function updateProvider(payload: Provider): TypesActionsProvider {
  return {
    type: UPDATE_PROVIDER,
    payload,
  };
}

function deleteProvider(payload: string): TypesActionsProvider {
  return {
    type: DELETE_PROVIDER,
    payload,
  };
}

export function resetProvidersState(): TypesActionsProvider {
  return {
    type: RESET_PROVIDERS_STATE,
  };
}

export function listProviderAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await providerDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listProvider(response.data));
  };
}

export function showProviderAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await providerDao.show(id, accountId);
      dispatch(showProvider(response.data));
    } catch (request) {
      alert(JSON.parse(request.response).message);
      callback();
    }
  };
}

export function createProviderAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await ProviderRepository.create(form);
      callbackSuccess();
      dispatch(showProvider(response.data));
    } catch (request) {
      callbackError();
    }
  };
}

export function updateProviderAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await ProviderRepository.update(form);

      dispatch(updateProvider(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function deleteProviderAsync(
  form: {
    id: string;
    accountId: string;
  },
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      await ProviderRepository.delete(form);
      dispatch(deleteProvider(form.id));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}
