import {
  ADD_FOOD_COMPLEMENT,
  DELETE_FOOD_COMPLEMENT,
  LIST_FOOD_COMPLEMENTS,
  TypesActionsFoodComplements,
  UPDATE_FOOD_COMPLEMENT,
  GET_LIST_FOOD_COMPLEMENT,
  RESET_FOOD_COMPLEMENTS_STATE,
} from './FoodComplementsTypes';
import {
  FoodComplement,
  ResponseFoodComplementsProps,
} from '../../../types/foodComplements/FoodComplement';
import { FoodComplementsDao } from '../../../api/foodComplements/dao/foodComplements.dao';
import { FoodComplementsRepository } from '../../../api/foodComplements/repository/foodComplements.repository';
import { Alert } from 'react-native';
import { AxiosResponse } from 'axios';

export function listFoodComplements(
  payload: ResponseFoodComplementsProps
): TypesActionsFoodComplements {
  return {
    type: LIST_FOOD_COMPLEMENTS,
    payload,
  };
}

function getListComplements(
  payload: FoodComplement[]
): TypesActionsFoodComplements {
  return {
    type: GET_LIST_FOOD_COMPLEMENT,
    payload,
  };
}

export function addNewFoodComplement(
  foodComplement: FoodComplement
): TypesActionsFoodComplements {
  return {
    type: ADD_FOOD_COMPLEMENT,
    payload: foodComplement,
  };
}
export function updateFoodComplement(
  foodComplement: FoodComplement
): TypesActionsFoodComplements {
  return {
    type: UPDATE_FOOD_COMPLEMENT,
    payload: foodComplement,
  };
}

export function deleteFoodComplement(
  foodComplement: string
): TypesActionsFoodComplements {
  return {
    type: DELETE_FOOD_COMPLEMENT,
    payload: foodComplement,
  };
}

export function resetFoodComplementsState(): TypesActionsFoodComplements {
  return {
    type: RESET_FOOD_COMPLEMENTS_STATE,
  };
}

export function listFoodComplementsAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null
) {
  return async function (dispacth: Function) {
    await FoodComplementsDao.list(accountId, numberPage, limit, search).then(
      (response: AxiosResponse<ResponseFoodComplementsProps>) => {
        dispacth(listFoodComplements(response.data));
      }
    );
  };
}

export function deleteFoodComplementsAsync(
  accountId: string,
  data: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    try {
      await FoodComplementsRepository.delete(data);
      const response: AxiosResponse<ResponseFoodComplementsProps> =
        await FoodComplementsDao.list(accountId, null, null, null);
      dispacth(listFoodComplements(response.data));
      callbackSuccess();
    } catch (error) {
      callbackError();
    }
  };
}

export function createFoodComplementsAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodComplement> =
        await FoodComplementsRepository.create(form);
      dispacth(addNewFoodComplement(response.data));
      callbackSuccess();
    } catch ({ request }) {
      callbackError();
    }
  };
}

export function updateFoodComplementsAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodComplement> =
        await FoodComplementsRepository.update(form);
      dispacth(updateFoodComplement(response.data));
      callbackSuccess();
    } catch ({ request }) {
      callbackError();
    }
  };
}

export function showFoodComplementsAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    FoodComplementsDao.show(id)
      .then((response: AxiosResponse<FoodComplement>) => {
        dispacth(addNewFoodComplement(response.data));
      })
      .catch(({ request }) => {
        callback();
      });
  };
}

export function getListComplementsAsync(accountId: string) {
  return async function (dispatch: Function) {
    const response: AxiosResponse<FoodComplement[]> =
      await FoodComplementsDao.getList(accountId);
    dispatch(getListComplements(response.data));
  };
}
