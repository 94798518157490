import { AxiosResponse } from 'axios';

import {
  ADD_TABLE,
  DELETE_TABLE,
  LIST_TABLES,
  TypesActionsTables,
  UPDATE_TABLE,
  GET_TABLES,
  RESET_TABLES_STATE,
} from './TablesTypes';
import { Table, ResponseTablesProps } from '../../../types/tables/Tables';
import { TablesDao } from '../../..//api/tables/dao/tables.dao';
import { TablesRepository } from '../../..//api/tables/repository/tables.repository';
import { Alert } from 'react-native';

export function listTables(payload: ResponseTablesProps): TypesActionsTables {
  return {
    type: LIST_TABLES,
    payload,
  };
}

export function addNewTable(Table: Table): TypesActionsTables {
  return {
    type: ADD_TABLE,
    payload: Table,
  };
}
export function updateTable(Table: Table): TypesActionsTables {
  return {
    type: UPDATE_TABLE,
    payload: Table,
  };
}

export function deleteTable(Table: string): TypesActionsTables {
  return {
    type: DELETE_TABLE,
    payload: Table,
  };
}

function getTables(payload: Table[]): TypesActionsTables {
  return {
    type: GET_TABLES,
    payload,
  };
}

export function resetTablesState(): TypesActionsTables {
  return {
    type: RESET_TABLES_STATE,
  };
}

export function listTablesAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null
) {
  return async function (dispacth: Function) {
    await TablesDao.list(accountId, numberPage, limit, search).then(
      (response: AxiosResponse<ResponseTablesProps>) => {
        dispacth(listTables(response.data));
      }
    );
  };
}

export function deleteTablesAsync(accountId: string, data: object) {
  return function (dispacth: Function) {
    TablesRepository.delete(data).then(async (response: any) => {
      Alert.alert(
        'Hecho',
        `Mesa eliminada con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
      await TablesDao.list(accountId, null, null, null).then(
        (response: AxiosResponse<ResponseTablesProps>) => {
          dispacth(listTables(response.data));
        }
      );
    });
  };
}

export function createTablesAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Table> = await TablesRepository.create(
        form
      );
      dispacth(addNewTable(response.data));
      Alert.alert(
        'Hecho',
        `Mesa creada con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
    } catch ({ request }) {
      Alert.alert(
        'Error',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
      setValidate(true);
    }
  };
}

export function updateTablesAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Table> = await TablesRepository.update(
        form
      );
      dispacth(updateTable(response.data));
      Alert.alert(
        'Hecho',
        `Mesa actualizada con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
    } catch ({ request }) {
      Alert.alert(
        'Error',
        `Error en listar mesas`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
      setValidate(true);
    }
  };
}

export function showTablesAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    TablesDao.show(id)
      .then((response: any) => {
        dispacth(addNewTable(response.data));
      })
      .catch(({ request }) => {
        Alert.alert(
          'Error',
          `Error en listar mesas`,
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
        callback();
      });
  };
}

export function getTablesAsync(accountId: string, callback?: Function) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Table[]> = await TablesDao.getList(
        accountId
      );
      dispacth(getTables(response.data));
      callback && callback();
    } catch ({ request }) {
      callback && callback();
      Alert.alert(
        'Error',
        `Error en listar mesas`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
    }
  };
}
