import { warehouserDao } from '../../../api/warehouse/dao/warehouse.dao';
import { WarehouseRepository } from '../../../api/warehouse/repository/warehouse.repository';
import {
  TypesActionsWarehouse,
  LIST_WAREHOUSE,
  SHOW_WAREHOUSE,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE,
  RESET_WAREHOUSES_STATE,
} from './WarehouseTypes';
import {
  ResponseWarehouse,
  Warehouse,
} from '../../../types/warehouse/Warehouse';

function listWarehouse(payload: ResponseWarehouse): TypesActionsWarehouse {
  return {
    type: LIST_WAREHOUSE,
    payload,
  };
}

function showWarehouse(payload: Warehouse): TypesActionsWarehouse {
  return {
    type: SHOW_WAREHOUSE,
    payload,
  };
}

function updateWarehouse(payload: Warehouse): TypesActionsWarehouse {
  return {
    type: UPDATE_WAREHOUSE,
    payload,
  };
}

function deleteWarehouse(payload: string): TypesActionsWarehouse {
  return {
    type: DELETE_WAREHOUSE,
    payload,
  };
}

export function resetWarehousesState(): TypesActionsWarehouse {
  return {
    type: RESET_WAREHOUSES_STATE,
  };
}

export function listWarehouseAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await warehouserDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listWarehouse(response.data));
  };
}

export function showWarehouseAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await warehouserDao.show(id, accountId);
      dispatch(showWarehouse(response.data));
    } catch (request) {
      alert(JSON.parse(request.response).message);
      callback();
    }
  };
}

export function createWarehouseAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await WarehouseRepository.create(form);
      callbackSuccess();
      dispatch(showWarehouse(response.data));
    } catch (request) {
      callbackError();
    }
  };
}

export function updateWarehouseAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await WarehouseRepository.update(form);

      dispatch(updateWarehouse(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function deleteWarehouseAsync(
  form: {
    id: string;
    accountId: string;
  },
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      await WarehouseRepository.delete(form);
      dispatch(deleteWarehouse(form.id));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}
