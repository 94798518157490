import { CashRegisterDao } from '../../../api/cashRegister/dao/cashRegister.dao';
import { CashRegisterRepository } from '../../../api/cashRegister/repository/cashRegister.repository';
import {
  TypesActionsCashRegister,
  LIST_CASH_REGISTER,
  LIST_CASH_REGISTER2,
  SHOW_CASH_REGISTER,
  UPDATE_CASH_REGISTER,
  DELETE_CASH_REGISTER,
  RESET_CASH_REGISTER_STATE,
} from './CashRegisterTypes';
import {
  ResponseCashRegister,
  CashRegister,
} from '../../../types/cashRegister/CashRegister';

function listCashRegister(
  payload: ResponseCashRegister
): TypesActionsCashRegister {
  return {
    type: LIST_CASH_REGISTER,
    payload,
  };
}

function listCashRegister2(
  payload: ResponseCashRegister
): TypesActionsCashRegister {
  return {
    type: LIST_CASH_REGISTER2,
    payload,
  };
}

function showCashRegister(payload: CashRegister): TypesActionsCashRegister {
  return {
    type: SHOW_CASH_REGISTER,
    payload,
  };
}

function updateCashRegister(payload: CashRegister): TypesActionsCashRegister {
  return {
    type: UPDATE_CASH_REGISTER,
    payload,
  };
}

function deleteCashRegister(payload: string): TypesActionsCashRegister {
  return {
    type: DELETE_CASH_REGISTER,
    payload,
  };
}

export function resetCashRegisterState(): TypesActionsCashRegister {
  return {
    type: RESET_CASH_REGISTER_STATE,
  };
}

export function listCashRegisterAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await CashRegisterDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listCashRegister(response.data));
  };
}

export function listCashRegisterAsync2(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await CashRegisterDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listCashRegister2(response.data));
  };
}

export function showCashRegisterAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await CashRegisterDao.show(id, accountId);
      dispatch(showCashRegister(response.data));
    } catch (request) {
      callback();
    }
  };
}

export function createCashRegisterAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await CashRegisterRepository.create(form);
      callbackSuccess();
      dispatch(showCashRegister(response.data));
    } catch (request) {
      callbackError();
    }
  };
}

export function updateCashRegisterAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await CashRegisterRepository.update(form);

      dispatch(updateCashRegister(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function deleteCashRegisterAsync(
  form: {
    id: string;
    accountId: string;
  },
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      await CashRegisterRepository.delete(form);
      dispatch(deleteCashRegister(form.id));
      const response = await CashRegisterDao.list(
        form.accountId,
        null,
        null,
        null,
        null
      );
      dispatch(listCashRegister(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}
