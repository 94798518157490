import { Invoice, ResponseInvoiceProps } from '../../../types/payments/Payment';

export const LIST_INVOICES = 'LIST_INVOICES';

export const ADD_INVOICE = 'ADD_INVOICE';

export const CLEAR_INVOICE = 'CLEAR_INVOICE';

export const RESET_INVOICES_STATE = 'RESET_INVOICES_STATE';

interface ActionListInvoices {
  type: typeof LIST_INVOICES;
  payload: ResponseInvoiceProps;
}

interface ActionAddInvoice {
  type: typeof ADD_INVOICE;
  payload: Invoice;
}

interface ActionClearInvoice {
  type: typeof CLEAR_INVOICE;
}

export interface ActionResetInvoicesState {
  type: typeof RESET_INVOICES_STATE;
}

export type TypesActionsInvoices =
  | ActionListInvoices
  | ActionAddInvoice
  | ActionClearInvoice
  | ActionResetInvoicesState;
