export const darkBlueText = '#223354';
export const hoverDarkBlueText = '#223354b3';
export const primaryBlue = '#1a75ff';
export const secondaryBlue = '#0ab6ff';
export const softBlue1 = '#9BC3FF';

export const greenText = '#44d600';
export const borderGreen = '#3bb900';
export const greenCheck = '#44d700';
export const greenCheck2 = '#34C203';
export const greenShadow = '#44d60080';
export const hoverBtnGreen = '#36ab00';
export const lightGreen = '#ecfbe6';
export const greenButton1 = '#2A8C08';

export const primaryOrange = '#f49200';
export const primaryButton = '#f0532d';
export const primaryButtonHover = '#f7941e';
export const midOrange = '#ffa31a';
export const warningColor = '#ffaf39';
export const lightOrange = '#fff6e8';
export const lightOrange2 = '#FFF1C1';
export const tintYellow = '#efcb9126';

export const primaryRed = '#ff1a43';
export const lightRed = '#ffe8ec';
export const secondaryRed = '#f4002c';
export const hoverRed = '#d72041';
export const errorRed = '#ff1943';

export const normalWhite = '#fff';

export const normalBlack = '#000';

export const softGray = '#8c95a7';
export const softGray2 = '#626e86';
export const softGray3 = '#f6f8fb';
export const softGray4 = '#f2eeea';
export const softGray5 = '#EDEDED';
export const softGray6 = '#D9D9D9';
export const inputGray = '#cccedd';
export const boldGrayText = '#797979';
export const hoverBtnGray = '#585d7f';
export const trendingGrayColor = '#ecfbe6';
export const softBorderGray = '#c4c4c4';
export const grayButton1 = '#9099a9';
export const grayText1 = '#6D6D6D';
export const inputBorderGray1 = '#bfbfbf';
export const tabButtonLightGray = '#d6d4d4';
export const appBackgroundGray = '#f2f2f2';

//statusColors
export const statusColors = {
  paid: greenCheck2,
  pending: primaryButtonHover,
  completed: darkBlueText,
  inProgress: primaryBlue,
  generated: grayText1,
  saved: normalWhite,
  available: normalWhite,
  busy: primaryButtonHover,
  reserved: primaryBlue,
  cancelled: errorRed,
};
