import {
  TypesActionsAccountingPurchase,
  LIST_ACCOUNTING_PURCHASE,
  SHOW_ACCOUNTING_PURCHASE,
  UPDATE_ACCOUNTING_PURCHASE,
  DELETE_ACCOUNTING_PURCHASE,
  RESET_ACCOUNTING_PURCHASE_STATE,
} from '../../actions/accountingPurchase/AccountingPurchaseTypes';
import { AccountingPurchaseState } from '../../models/accountingPurchase/AccountingPurchaseState';
import { generalResponse } from '../../../types/common/interfaces';
import { AccountingPurchaseValues } from '../../../types/accountingPurchase/interface';

const initialState: AccountingPurchaseState = {
  accountingPurchases: { ...generalResponse, totalAmount: 0 },
  accountingPurchase: AccountingPurchaseValues,
};

export default function accountingPurchasesReducer(
  state = initialState,
  action: TypesActionsAccountingPurchase
) {
  switch (action.type) {
    case LIST_ACCOUNTING_PURCHASE: {
      const accountingPurchases = action.payload;
      return {
        ...state,
        accountingPurchases,
      };
    }
    case SHOW_ACCOUNTING_PURCHASE: {
      const accountingPurchase = action.payload;
      return {
        ...state,
        accountingPurchase,
      };
    }
    case UPDATE_ACCOUNTING_PURCHASE: {
      const accountingPurchase = action.payload;
      const index = state.accountingPurchases.docs.findIndex(
        (todo) => todo._id === accountingPurchase._id
      );
      const newArray = [...state.accountingPurchases.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.accountingPurchases;
      const recipes = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        recipes,
      };
    }
    // case DELETE_ACCOUNTING_PURCHASE: {
    //   const id = action.payload;
    //   return {
    //     ...state,
    //     recipes: {
    //       docs: [...state.accountingPurchases.docs.filter((e) => e._id !== id)],
    //     },
    //   };
    // }
    case RESET_ACCOUNTING_PURCHASE_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
