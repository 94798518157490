import { Alert } from 'react-native';
import { AxiosResponse } from 'axios';

import {
  ADD_FOOD_CATEGORY,
  DELETE_FOOD_CATEGORY,
  LIST_FOOD_CATEGORIES,
  TypesActionsFoodCategories,
  UPDATE_FOOD_CATEGORY,
  GET_CATEGORIES,
  RESET_FOOD_CATEGORIES_STATE,
} from './FoodCategoriesTypes';
import {
  FoodCategory,
  ResponseFoodCategoriesProps,
} from '../../../types/foodCategories/FoodCategory';
import { FoodCategoriesDao } from '../../../api/foodCategories/dao/foodCategories.dao';
import { FoodCategoriesRepository } from '../../../api/foodCategories/repository/foodCategories.repository';

export function listFoodCategories(
  payload: ResponseFoodCategoriesProps
): TypesActionsFoodCategories {
  return {
    type: LIST_FOOD_CATEGORIES,
    payload,
  };
}

export function addNewFoodCategory(
  foodCategory: FoodCategory
): TypesActionsFoodCategories {
  return {
    type: ADD_FOOD_CATEGORY,
    payload: foodCategory,
  };
}
export function updateFoodCategory(
  foodCategory: FoodCategory
): TypesActionsFoodCategories {
  return {
    type: UPDATE_FOOD_CATEGORY,
    payload: foodCategory,
  };
}

export function deleteFoodCategory(
  foodCategory: string
): TypesActionsFoodCategories {
  return {
    type: DELETE_FOOD_CATEGORY,
    payload: foodCategory,
  };
}

export function getFoodCategories(
  payload: FoodCategory[]
): TypesActionsFoodCategories {
  return {
    type: GET_CATEGORIES,
    payload,
  };
}

export function resetFoodCategoriesState(): TypesActionsFoodCategories {
  return {
    type: RESET_FOOD_CATEGORIES_STATE,
  };
}

export function listFoodCategoriesAsync(
  numberPage: number,
  limit: number,
  search: string | null
) {
  return async function (dispacth: Function) {
    await FoodCategoriesDao.list(numberPage, limit, search).then(
      (response: AxiosResponse<ResponseFoodCategoriesProps>) => {
        dispacth(listFoodCategories(response.data));
      }
    );
  };
}

export function deleteFoodCategoriesAsync(
  data: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    try {
      await FoodCategoriesRepository.delete(data);
      const response: AxiosResponse<ResponseFoodCategoriesProps> =
        await FoodCategoriesDao.list(null, null, null);
      dispacth(listFoodCategories(response.data));
      callbackSuccess();
    } catch ({ request }) {
      callbackError();
    }
  };
}

export function createFoodCategoriesAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodCategory> =
        await FoodCategoriesRepository.create(form);
      dispacth(addNewFoodCategory(response.data));
      callbackSuccess();
    } catch ({ request }) {
      callbackError();
    }
  };
}

export function updateFoodCategoriesAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodCategory> =
        await FoodCategoriesRepository.update(form);
      dispacth(updateFoodCategory(response.data));
      callbackSuccess();
    } catch ({ request }) {
      callbackError();
    }
  };
}

export function showFoodCategoriesAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    FoodCategoriesDao.show(id)
      .then((response: AxiosResponse<FoodCategory>) => {
        dispacth(addNewFoodCategory(response.data));
      })
      .catch(({ request }) => {
        // Alert.alert(
        //   'Error',
        //   `${JSON.parse(request.response).message}`,
        //   [
        //     {
        //       text: 'OK',
        //       style: 'cancel',
        //       onPress: () => {},
        //     },
        //   ],
        //   { cancelable: false }
        // );
        callback();
      });
  };
}

export async function getAccountsListAsync() {
  return await FoodCategoriesDao.getAccountsList();
}

export function getFoodCategoriesAsync(accountId: string, callback?: Function) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodCategory[]> =
        await FoodCategoriesDao.getList(accountId);
      dispacth(getFoodCategories(response.data));
      callback && callback();
    } catch (error) {
      callback && callback();
      console.log(error);
    }
  };
}

export async function getFoodCategoriesList(accountId: string) {
  const categories: AxiosResponse<FoodCategory[]> =
    await FoodCategoriesDao.getList(accountId);
  return categories;
}
