import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import { Sale } from '../../../types/sales/Sales';

export const SalesRepository = {
  create: (form: object): Promise<AxiosResponse<Sale>> => {
    return axiosIntance.post('/sales', form);
  },
  update: (form: object): Promise<AxiosResponse<Sale>> => {
    return axiosIntance.put('/sales', form);
  },
  delete: (form: object): Promise<AxiosResponse<{ sucess: boolean }>> => {
    return axiosIntance.delete('/sales', { data: form });
  },
};
