import { AccountingPurchaseDao } from '../../../api/accountingPurchase/dao/accountingPurchase.dao';
import { AccountingPurchaseRepository } from '../../../api/accountingPurchase/repository/accountingPurchase.repository';
import {
  TypesActionsAccountingPurchase,
  LIST_ACCOUNTING_PURCHASE,
  SHOW_ACCOUNTING_PURCHASE,
  UPDATE_ACCOUNTING_PURCHASE,
  DELETE_ACCOUNTING_PURCHASE,
  RESET_ACCOUNTING_PURCHASE_STATE,
} from './AccountingPurchaseTypes';
import {
  ResponseAccountingPurchase,
  AccountingPurchase,
} from '../../../types/accountingPurchase/accountingPurchase';

function listAccountingPurchase(
  payload: ResponseAccountingPurchase
): TypesActionsAccountingPurchase {
  return {
    type: LIST_ACCOUNTING_PURCHASE,
    payload,
  };
}

function showAccountingPurchase(
  payload: AccountingPurchase
): TypesActionsAccountingPurchase {
  return {
    type: SHOW_ACCOUNTING_PURCHASE,
    payload,
  };
}

function updateAccountingPurchase(
  payload: AccountingPurchase
): TypesActionsAccountingPurchase {
  return {
    type: UPDATE_ACCOUNTING_PURCHASE,
    payload,
  };
}

function deleteAccountingPurchase(
  payload: string
): TypesActionsAccountingPurchase {
  return {
    type: DELETE_ACCOUNTING_PURCHASE,
    payload,
  };
}

export function resetAccountingPurchaseState(): TypesActionsAccountingPurchase {
  return {
    type: RESET_ACCOUNTING_PURCHASE_STATE,
  };
}

export function listAccountingPurchaseAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await AccountingPurchaseDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listAccountingPurchase(response.data));
  };
}

export function showAccountingPurchaseAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await AccountingPurchaseDao.show(id, accountId);
      dispatch(showAccountingPurchase(response.data));
    } catch (request) {
      callback();
    }
  };
}

export function createAccountingPurchaseAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await AccountingPurchaseRepository.create(form);
      callbackSuccess();
      dispatch(showAccountingPurchase(response.data));
    } catch (request) {
      callbackError();
    }
  };
}

export function updateAccountingPurchaseAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await AccountingPurchaseRepository.update(form);

      dispatch(updateAccountingPurchase(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function deleteAccountingPurchaseAsync(
  form: {
    id: string;
    accountId: string;
  },
  type: string,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      await AccountingPurchaseRepository.delete(form);
      const response = await AccountingPurchaseDao.list(
        form.accountId,
        null,
        null,
        null,
        `type=${type}`
      );
      dispatch(deleteAccountingPurchase(form.id));
      dispatch(listAccountingPurchase(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}
