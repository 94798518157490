import React from 'react';
import { Suspense } from 'react';
import {
  ActivityIndicator,
  ColorValue,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import { primaryOrange } from '../stylesheets/variables';

interface LoaderProps {
  size?: number | 'small' | 'large';
  color?: ColorValue;
  styleContainer?: StyleProp<ViewStyle>;
  styleLoader?: StyleProp<ViewStyle>;
}

export const Loader: React.FC<LoaderProps> = ({
  size = 'large',
  color = primaryOrange,
  styleLoader = {},
  styleContainer = {},
}) => (
  <View style={[styles.container, styles.horizontal, styleContainer]}>
    <ActivityIndicator size={size} color={color} style={styleLoader} />
  </View>
);

const SuspenseLoader = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
});

export default SuspenseLoader;
