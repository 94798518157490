import {
  TypesActionsCashRegisterMovement,
  LIST_CASH_REGISTER_MOVEMENT,
  SHOW_CASH_REGISTER_MOVEMENT,
  UPDATE_CASH_REGISTER_MOVEMENT,
  LIST_CASH_MOVEMENTS,
  RESET_CASH_REGISTER_MOVEMENTS_STATE,
} from '../../actions/cashRegisterMovement/CashRegisterMovementTypes';
import { CashRegisterMovementState } from '../../models/cashRegisterMovement/CashRegisterMovementState';
import { cashRegisterMovementInitialValues } from '../../../types/cashRegisterMovement/interfaces';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: CashRegisterMovementState = {
  cashRegisterMovements: generalResponse,
  cashRegisterMovement: cashRegisterMovementInitialValues,
  cashMovements: generalResponse,
};

export default function cashRegisterMovementReducer(
  state = initialState,
  action: TypesActionsCashRegisterMovement
) {
  switch (action.type) {
    case LIST_CASH_REGISTER_MOVEMENT: {
      const cashRegisterMovements = action.payload;
      return {
        ...state,
        cashRegisterMovements,
      };
    }
    case LIST_CASH_MOVEMENTS: {
      const cashMovements = action.payload;
      return {
        ...state,
        cashMovements,
      };
    }
    case SHOW_CASH_REGISTER_MOVEMENT: {
      const cashRegisterMovement = action.payload;
      return {
        ...state,
        cashRegisterMovement,
      };
    }
    case UPDATE_CASH_REGISTER_MOVEMENT: {
      const cashRegisterMovement = action.payload;
      const index = state.cashRegisterMovements.docs.findIndex(
        (todo) => todo._id === cashRegisterMovement._id
      );
      const newArray = [...state.cashRegisterMovements.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.cashRegisterMovements;
      const cashRegisterMovements = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        cashRegisterMovements,
      };
    }
    case RESET_CASH_REGISTER_MOVEMENTS_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
