import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/ApiConfig';

export const FoodsDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }

    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(
      `/food?page=${page}&limit=${limit}&account=${accountId}${query}`
    );
  },
  show: (id: string) => {
    return axiosIntance.get(`/food/${id}`);
  },
  getAccountsList: (accountId: string) => {
    return axiosIntance.get(`/${accountId}/accounts/list`);
  },
  getList: (accountId: string) => {
    return axiosIntance.get(`/food/list?account=${accountId}`);
  },
  getExcel: (
    accountId: string,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<Blob>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    return axiosIntance.get(
      `/food/list/export?account=${accountId}${query}${filter}`
    );
  },
};
