import {
  ADD_USER_AUTH,
  CHANGE_ACCOUNT,
  UPDATE_USER_AUTH,
  CLEAR_USER_AUTH,
  TypesActionsUser
} from '../../actions/user/UserTypesActions';
import { UserStateAuth } from '../../models/user/UserStateAuth';
import { UserAuthInitialValues } from '../../../types/users/interfaces';
import { UserAuth } from '../../../types/users/User';
import AsyncStorage from '@react-native-async-storage/async-storage';

const getDataUser = async () => {
  const user: UserAuth = JSON.parse(await AsyncStorage.getItem('user'));
  return { user: user };
};

// const initialState: UserStateAuth = AsyncStorage.getItem('user')
//   ? getDataUser()
//   : {
//     user: UserAuthInitialValues
//   }; Se debe hacer fix de validacion

const initialState: UserStateAuth = {
  user: UserAuthInitialValues
};

export default function userReducer(
  state = initialState,
  action: TypesActionsUser
): UserStateAuth {
  switch (action.type) {
    case ADD_USER_AUTH: {
      let user = action.payload;
      user.accessToken = action.accessToken;
      return {
        ...state,
        user
      };
    }
    case CHANGE_ACCOUNT: {
      const user = action.payload;
      return {
        ...state,
        user
      };
    }
    case UPDATE_USER_AUTH: {
      const user = action.payload;
      return {
        ...state,
        user
      };
    }
    case CLEAR_USER_AUTH: {
      return {
        ...state,
        user: UserAuthInitialValues
      };
    }
    default:
      return state;
  }
}
