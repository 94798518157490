import { Provider, ResponseProvider } from '../../../types/provider/Provider';

export const LIST_PROVIDER = 'LIST_PROVIDER';
export const SHOW_PROVIDER = 'SHOW_PROVIDER';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const DELETE_PROVIDER = 'DELETE_PROVIDER';
export const RESET_PROVIDERS_STATE = 'RESET_PROVIDERS_STATE';

interface ActionListProvider {
  type: typeof LIST_PROVIDER;
  payload: ResponseProvider;
}

interface ActionShowProvider {
  type: typeof SHOW_PROVIDER;
  payload: Provider;
}

interface ActionUpdateProvider {
  type: typeof UPDATE_PROVIDER;
  payload: Provider;
}

interface ActionDeleteProvider {
  type: typeof DELETE_PROVIDER;
  payload: string;
}

interface ActionResetProvidersState {
  type: typeof RESET_PROVIDERS_STATE;
}

export type TypesActionsProvider =
  | ActionListProvider
  | ActionShowProvider
  | ActionUpdateProvider
  | ActionDeleteProvider
  | ActionResetProvidersState;
