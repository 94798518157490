import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import {
  Inventory,
  ResponseInventory,
  ResponseInventoryMovements,
} from '../../../types/inventory/Inventory';
import { getFoodsExcel } from 'src/store/actions/foods/FoodsActions';

export const inventoryDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseInventory>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(
      `/product/list?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  show: (id: string, accountId: string): Promise<AxiosResponse<Inventory>> => {
    return axiosIntance.get(`/product/${accountId}/${id}`);
  },
  listMovements: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
    // productId: string | null
  ): Promise<AxiosResponse<ResponseInventoryMovements>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    // if (productId) {
    //   productId = `&productId=${productId}`;
    // } else {
    //   productId = '';
    // }
    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(
      `/inventory/list?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  getFoodsExcelInventory: (
    accountId: string,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<Blob>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    return axiosIntance.get(
      `/inventory/export-excel?accountId=${accountId}${query}${filter}`
    );
  },
};
