import { AxiosResponse } from 'axios';

import {
  DataPaymentInvoiceDTO,
  MakeElectronicInvoiceDTO,
  PaymentInvoiceDTO,
  ResendInvoiceDTO,
} from 'src/types/checkIn/interface';
import { axiosIntance } from '../../../config/ApiConfig';
import {
  ChangePaymentFoodStatus,
  RemoveFoodOrder,
} from '../../../types/orders/interfaces';
import { OrderInvoice } from '../../../types/orders/Orders';

export const OrdersRepository = {
  delete: (data: any) => {
    return axiosIntance.delete(`/orders`, { data: { id: data._id, ...data } });
  },
  create: (form: object) => {
    return axiosIntance.post(`/orders`, form);
  },
  update: (form: object) => {
    return axiosIntance.put(`/orders`, form);
  },
  updateInvoiceStatus: (form: object) => {
    return axiosIntance.post(`/orders/invoice/change-status`, form);
  },
  createElectronicCreditNote: (form: object) => {
    return axiosIntance.post(`/orders/electronicInvoice/credit-note`, form);
  },
  changeFoodStatus: (form: object) => {
    return axiosIntance.post('/orders/food/status/change', form);
  },
  multiPayment: (form: object) => {
    return axiosIntance.post('/orders/food/multipayment', form);
  },
  completeElectronicInvoice: (id: string) => {
    return axiosIntance.post('/orders/electronicInvoice/complete', { id });
  },
  changePaymentFoodStatus: (data: ChangePaymentFoodStatus) => {
    return axiosIntance.post('/orders/food/payment', data);
  },
  removeFood: (data: RemoveFoodOrder) => {
    return axiosIntance.post('/orders/food/remove', data);
  },
  invoicePay: (data: PaymentInvoiceDTO) => {
    return axiosIntance.post('/orders/invoice/pay', data);
  },
  makeElectronicInvoice: (
    data: MakeElectronicInvoiceDTO
  ): Promise<AxiosResponse<OrderInvoice>> => {
    return axiosIntance.post('/orders/electronicInvoice/make', data);
  },
  resendElectronicInvoice: (data: ResendInvoiceDTO) => {
    return axiosIntance.post('/orders/electronicInvoice/resend', data);
  },
  changePaymentMethod: (
    data: DataPaymentInvoiceDTO
  ): Promise<AxiosResponse<OrderInvoice>> => {
    return axiosIntance.post('/orders/invoice/changePaymentMethod', data);
  },
  sendTicket: (data: ResendInvoiceDTO) => {
    return axiosIntance.post('/orders/invoice/send-ticket', data);
  },
};
