import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/ApiConfig';
import {
  CashRegisterMovement,
  CashMovement,
} from '../../../types/cashRegisterMovement/CashRegisterMovement';

export const CashRegisterMovementRepository = {
  create: (form: object): Promise<AxiosResponse<CashRegisterMovement>> => {
    return axiosIntance.post('/cashRegisterMovement', form);
  },
  update: (form: object): Promise<AxiosResponse<CashRegisterMovement>> => {
    return axiosIntance.put('/cashRegisterMovement', form);
  },
  createCashMovement: (form: object): Promise<AxiosResponse<CashMovement>> => {
    return axiosIntance.post('/cashRegisterMovement/cash', form);
  },
};
