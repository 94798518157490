import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import { Purchase } from '../../../types/shopping/Shopping';

export const PurchaseRepository = {
  create: (form: object): Promise<AxiosResponse<Purchase>> => {
    return axiosIntance.post('/purchase', form);
  },
  update: (form: object): Promise<AxiosResponse<Purchase>> => {
    return axiosIntance.put('/purchase', form);
  },
  delete: (form: object): Promise<AxiosResponse<{ sucess: boolean }>> => {
    return axiosIntance.delete('/purchase', { data: form });
  },
};
