import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import {
  Inventory,
  InventoryMovement,
} from '../../../types/inventory/Inventory';

export const InventoryRepository = {
  create: (form: object): Promise<AxiosResponse<Inventory>> => {
    return axiosIntance.post('/product', form);
  },
  update: (form: object): Promise<AxiosResponse<Inventory>> => {
    return axiosIntance.put('/product', form);
  },
  delete: (form: object): Promise<AxiosResponse<{ sucess: boolean }>> => {
    return axiosIntance.delete('/product', { data: form });
  },
  createMovement: (form: object): Promise<AxiosResponse<InventoryMovement>> => {
    return axiosIntance.post('inventory/adjustment', form);
  },
};
