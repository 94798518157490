import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/ApiConfig';
import { CashRegister } from '../../../types/cashRegister/CashRegister';

export const CashRegisterRepository = {
  create: (form: object): Promise<AxiosResponse<CashRegister>> => {
    return axiosIntance.post('/cashRegister', form);
  },
  update: (form: object): Promise<AxiosResponse<CashRegister>> => {
    return axiosIntance.put('/cashRegister', form);
  },
  delete: (form: object): Promise<AxiosResponse<{ sucess: boolean }>> => {
    return axiosIntance.delete('/cashRegister', { data: form });
  },
};
