import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import { Warehouse } from '../../../types/warehouse/Warehouse';

export const WarehouseRepository = {
  create: (form: object): Promise<AxiosResponse<Warehouse>> => {
    return axiosIntance.post('/warehouse', form);
  },
  update: (form: object): Promise<AxiosResponse<Warehouse>> => {
    return axiosIntance.put('/warehouse', form);
  },
  delete: (form: object): Promise<AxiosResponse<{ sucess: boolean }>> => {
    return axiosIntance.delete('/warehouse', { data: form });
  },
};
