import {
  TypesActionsInvoices,
  LIST_INVOICES,
  ADD_INVOICE,
  CLEAR_INVOICE,
  RESET_INVOICES_STATE,
} from '../../actions/invoice/invoiceTypes';
import { InvoicesState } from '../../models/invoices/InvoiceState';
import { InvoiceInitialValues } from '../../../types/payments/interfaces';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: InvoicesState = {
  invoices: generalResponse,
  invoice: InvoiceInitialValues,
};

export default function invoicesReducer(
  state = initialState,
  action: TypesActionsInvoices
) {
  switch (action.type) {
    case LIST_INVOICES: {
      const invoices = action.payload;
      return {
        ...state,
        invoices,
      };
    }
    case ADD_INVOICE: {
      const invoice = action.payload;
      return {
        ...state,
        invoice,
      };
    }
    case CLEAR_INVOICE: {
      return {
        ...state,
        invoice: InvoiceInitialValues,
      };
    }
    case RESET_INVOICES_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
