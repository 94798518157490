import {
  TypesActionsCashRegister,
  LIST_CASH_REGISTER,
  LIST_CASH_REGISTER2,
  SHOW_CASH_REGISTER,
  UPDATE_CASH_REGISTER,
  DELETE_CASH_REGISTER,
  RESET_CASH_REGISTER_STATE,
} from '../../../store/actions/cashRegister/CashRegisterTypes';
import { CashRegisterState } from '../../../store/models/cashRegister/CashRegisterState';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: CashRegisterState = {
  cashRegisters: generalResponse,
  cashRegisters2: generalResponse,
  cashRegister: {
    _id: '',
    accountId: '',
    name: '',
    terminalNumber: '',
    description: '',
    createdAt: '',
    deletedAt: null,
    __v: 0,
  },
};

export default function cashRegisterReducer(
  state = initialState,
  action: TypesActionsCashRegister
) {
  switch (action.type) {
    case LIST_CASH_REGISTER: {
      const cashRegisters = action.payload;
      return {
        ...state,
        cashRegisters,
      };
    }
    case LIST_CASH_REGISTER2: {
      const cashRegisters2 = action.payload;
      return {
        ...state,
        cashRegisters2,
      };
    }
    case SHOW_CASH_REGISTER: {
      const cashRegister = action.payload;
      return {
        ...state,
        cashRegister,
      };
    }
    case UPDATE_CASH_REGISTER: {
      const cashRegister = action.payload;
      const index = state.cashRegisters.docs.findIndex(
        (todo) => todo._id === cashRegister._id
      );
      const newArray = [...state.cashRegisters.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.cashRegisters;
      const cashRegisters = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        cashRegisters,
      };
    }
    case DELETE_CASH_REGISTER: {
      const id = action.payload;
      return {
        ...state,
        cashRegisters: {
          docs: [...state.cashRegisters.docs.filter((e) => e._id !== id)],
        },
      };
    }
    case RESET_CASH_REGISTER_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
