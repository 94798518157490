import {
  CashRegisterMovement,
  ResponseCashRegisterMovement,
  CashMovement,
  ResponseCashMovement,
} from '../../../types/cashRegisterMovement/CashRegisterMovement';

export const LIST_CASH_REGISTER_MOVEMENT = 'LIST_CASH_REGISTER_MOVEMENT';
export const SHOW_CASH_REGISTER_MOVEMENT = 'SHOW_CASH_REGISTER_MOVEMENT';
export const UPDATE_CASH_REGISTER_MOVEMENT = 'UPDATE_CASH_REGISTER_MOVEMENT';
export const LIST_CASH_MOVEMENTS = 'LIST_CASH_MOVEMENTS';
export const SHOW_CASH_MOVEMENT = 'SHOW_CASH_MOVEMENT';
export const RESET_CASH_REGISTER_MOVEMENTS_STATE =
  'RESET_CASH_REGISTER_MOVEMENTS_STATE';

interface ActionListCashRegisterMovement {
  type: typeof LIST_CASH_REGISTER_MOVEMENT;
  payload: ResponseCashRegisterMovement;
}

interface ActionShowCashRegisterMovement {
  type: typeof SHOW_CASH_REGISTER_MOVEMENT;
  payload: CashRegisterMovement;
}

interface ActionUpdateCashRegisterMovement {
  type: typeof UPDATE_CASH_REGISTER_MOVEMENT;
  payload: CashRegisterMovement;
}

interface ActionListCashMovements {
  type: typeof LIST_CASH_MOVEMENTS;
  payload: ResponseCashMovement;
}

interface ActionShowCashMovement {
  type: typeof SHOW_CASH_MOVEMENT;
  payload: CashMovement;
}

export interface ActionResetCashRegisterMovementsState {
  type: typeof RESET_CASH_REGISTER_MOVEMENTS_STATE;
}

export type TypesActionsCashRegisterMovement =
  | ActionListCashRegisterMovement
  | ActionShowCashRegisterMovement
  | ActionUpdateCashRegisterMovement
  | ActionListCashMovements
  | ActionShowCashMovement
  | ActionResetCashRegisterMovementsState;
