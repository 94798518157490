import { AxiosResponse } from 'axios';
import {
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  LIST_ACCOUNTS,
  RESET_ACCOUNTS_STATE,
  TypesActionsAccounts,
} from './AccountsTypes';
import {
  Account,
  ResponseAccountsProps,
} from '../../../types/accounts/Accounts';
import { AccountsDao } from '../../../api/accounts/dao/accounts.dao';
import { AccountsRepository } from '../../../api/accounts/repository/accounts.repository';
import { Alert } from 'react-native';
import { UserAuth } from '../../../types/users/User';
import AsyncStorage from '@react-native-async-storage/async-storage';

function listAccounts(payload: ResponseAccountsProps): TypesActionsAccounts {
  return {
    type: LIST_ACCOUNTS,
    payload,
  };
}

function addNewAccount(account: Account): TypesActionsAccounts {
  return {
    type: ADD_ACCOUNT,
    payload: account,
  };
}

export function deleteAccount(account: string): TypesActionsAccounts {
  return {
    type: DELETE_ACCOUNT,
    payload: account,
  };
}

export function resetAccountsState(): TypesActionsAccounts {
  return {
    type: RESET_ACCOUNTS_STATE,
  };
}

export function listAccountsAsync(
  numberPage: number,
  limit: number,
  search: string | null
) {
  return async function (dispacth: Function) {
    await AccountsDao.list(numberPage, limit, search).then(
      (response: AxiosResponse<ResponseAccountsProps>) => {
        dispacth(listAccounts(response.data));
      }
    );
  };
}

export function deleteAccountsAsync(data: object) {
  return function (dispacth: Function) {
    AccountsRepository.delete(data).then(async (response) => {
      Alert.alert('Hecho', `Restaurante eliminado con éxito`);
      await AccountsDao.list(null, null, null).then(
        (response: AxiosResponse<ResponseAccountsProps>) => {
          dispacth(listAccounts(response.data));
        }
      );
    });
  };
}

export function showAccountsAsync(id: string, callback?: Function) {
  return function (dispacth: Function) {
    AccountsDao.show(id)
      .then((response: AxiosResponse<Account>) => {
        dispacth(addNewAccount(response.data));
      })
      .catch(({ request }) => {
        callback && callback();
      });
  };
}

export const createAccountAsync = (
  form: Object,
  callback: Function,
  setValidate: Function
) => {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Account> = await AccountsRepository.create(
        form
      );
      dispacth(addNewAccount(response.data));
      Alert.alert(
        'Hecho',
        `Restaurante creado con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
    } catch ({ request }) {
      Alert.alert(
        'Error',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
      setValidate(true);
    }
  };
};

export const editAccountAsync = (
  form: Account,
  callback: Function,
  setValidate: (value: boolean) => void
) => {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Account> = await AccountsRepository.update(
        form
      );
      const accountUpdate: UserAuth = JSON.parse(
        await AsyncStorage.getItem('user')
      );
      const foundIndex = accountUpdate.account.findIndex(
        (x) => x.id === form.id
      );
      accountUpdate.account[foundIndex] = form;
      AsyncStorage.setItem('user', JSON.stringify(accountUpdate));
      dispacth(addNewAccount(response.data));
      Alert.alert(
        'Hecho',
        `Restaurante actualizado con éxito`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
    } catch ({ request }) {
      Alert.alert(
        'Error',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {
              callback();
            },
          },
        ],
        { cancelable: false }
      );
      setValidate(true);
    }
  };
};
