import {
  ADD_FOOD,
  DELETE_FOOD,
  LIST_FOODS,
  TypesActionsFoods,
  UPDATE_FOOD,
  GET_FOODS,
  RESET_FOODS_STATE,
} from './FoodsTypes';
import { Food, ResponseFoodsProps } from '../../../types/foods/Food';
import { FoodsDao } from '../../..//api/foods/dao/foods.dao';
import { FoodsRepository } from '../../..//api/foods/repository/foods.repository';
import { AxiosResponse } from 'axios';

export function listFoods(payload: ResponseFoodsProps): TypesActionsFoods {
  return {
    type: LIST_FOODS,
    payload,
  };
}

function getFoods(payload: Food[]): TypesActionsFoods {
  return {
    type: GET_FOODS,
    payload,
  };
}

export function addNewFood(foodComplement: Food): TypesActionsFoods {
  return {
    type: ADD_FOOD,
    payload: foodComplement,
  };
}
export function updateFood(foodComplement: Food): TypesActionsFoods {
  return {
    type: UPDATE_FOOD,
    payload: foodComplement,
  };
}

export function deleteFood(foodComplement: string): TypesActionsFoods {
  return {
    type: DELETE_FOOD,
    payload: foodComplement,
  };
}

export function resetFoodsState(): TypesActionsFoods {
  return {
    type: RESET_FOODS_STATE,
  };
}

export function listFoodsAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null
) {
  return async function (dispacth: Function) {
    await FoodsDao.list(accountId, numberPage, limit, search).then(
      (response: AxiosResponse<ResponseFoodsProps>) => {
        dispacth(listFoods(response.data));
      }
    );
  };
}

export function deleteFoodsAsync(
  accountId: string,
  data: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    try {
      await FoodsRepository.delete(data);
      const response = await FoodsDao.list(accountId, null, null, null);
      dispacth(listFoods(response.data));
      callbackSuccess();
    } catch (error) {
      callbackError();
    }
  };
}

export function createFoodsAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Food> = await FoodsRepository.create(form);
      dispacth(addNewFood(response.data));
      callbackSuccess();
    } catch ({ request }) {
      callbackError();
    }
  };
}

export function updateFoodsAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispacth: Function) {
    const response: AxiosResponse<Food> = await FoodsRepository.update(form);
    try {
      dispacth(updateFood(response.data));
      callbackSuccess();
    } catch ({ request }) {
      callbackError();
    }
  };
}

export function showFoodsAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    FoodsDao.show(id)
      .then((response: AxiosResponse<Food>) => {
        dispacth(addNewFood(response.data));
      })
      .catch(({ request }) => {
        callback();
      });
  };
}

export function getFoodsAsync(
  accountId: string,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Food[]> = await FoodsDao.getList(accountId);
      dispacth(getFoods(response.data));
      callbackSuccess && callbackSuccess();
    } catch ({ request }) {
      callbackError && callbackError();
    }
  };
}

export async function getFoodsExcel(
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return await FoodsDao.getExcel(accountId, search, filter);
}
