import * as Yup from 'yup';

import { ResponseRecipe, Recipe } from './Recipe';
import { UserAuth } from '../users/User';
import { Food } from '../foods/Food';
import { ResponseInventory } from '../inventory/Inventory';
import { FoodRecipeType, IngredientType } from '../../utils/enums';
import { FoodComplement } from '../foodComplements/FoodComplement';

export interface RecipeMainProps {
  recipes: ResponseRecipe;
  listRecipe: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deleteRecipe: (
    form: {
      id: string;
      accountId: string;
    },
    type: string,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
  foods: Food[];
  getFoods: (
    accountId: string,
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
  foodComplementsList: FoodComplement[];
  getFoodComplements: (accountId: string) => Promise<void>;
}

export interface RecipeCreateProps {
  createRecipe: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
  foods: Food[];
  getFoods: (
    accountId: string,
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
  inventories: ResponseInventory;
  getProducts: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  recipes: ResponseRecipe;
  getRecipes: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  foodComplementsList: FoodComplement[];
  getFoodComplements: (accountId: string) => Promise<void>;
}

export interface RecipeEditProps {
  recipe: Recipe;
  showRecipe: (
    id: string,
    accountId: string,
    callback: Function
  ) => Promise<void>;
  updateRecipe: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
  foods: Food[];
  getFoods: (
    accountId: string,
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
  inventories: ResponseInventory;
  getProducts: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  recipes: ResponseRecipe;
  getRecipes: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface RecipeFormValues {
  id?: string;
  _id?: string;
  name: string;
  accountId?: string;
  accompanimentId?: string;
  foodId?: string;
  ingredients: Ingredient[];
  type?: string;
  productBaseId?: string;
  foodType?: string;
}
export const initialValues: RecipeFormValues = {
  id: '',
  _id: '',
  accountId: '',
  name: '',
  foodId: '',
  ingredients: [],
  type: '',
  productBaseId: '',
  accompanimentId: '',
  foodType: FoodRecipeType.food,
};

export interface Ingredient {
  type?: string;
  product?: string;
  recipe?: string;
  qty: number;
  loss: number;
  price: number;
  unit: string;
  lossPrice: number;
  totalPrice: number;
  productUnitPrice?: number;
  id?: number;
}

export const ingredientInitialValues: Ingredient = {
  type: IngredientType.PRODUCT,
  product: '',
  qty: 1,
  loss: 0,
  price: 0,
  unit: '',
  lossPrice: 0,
  totalPrice: 0,
  productUnitPrice: 0,
  id: null,
  recipe: '',
};

export const validationSchema: Yup.SchemaOf<RecipeFormValues> = Yup.object({
  id: Yup.string(),
  _id: Yup.string(),
  accountId: Yup.string(),
  foodId: Yup.string().when('foodType', (foodType: string) => {
    if (foodType === FoodRecipeType.food) {
      return Yup.string().required('Este campo es requerido');
    } else {
      Yup.string().nullable();
    }
  }),
  accompanimentId: Yup.string().when('foodType', (foodType: string) => {
    if (foodType === FoodRecipeType.foodComplement) {
      return Yup.string().required('Este campo es requerido');
    } else {
      Yup.string().nullable();
    }
  }),
  foodType: Yup.string().nullable(),
  // name: Yup.string().when('ingredients', (ingredients: Ingredient[]) => {
  //   if (ingredients.length) {
  //     return Yup.string();
  //   } else {
  //     return Yup.string().required('Este campo es requerido');
  //   }
  // }),
  name: Yup.string().required('Este campo es requerido'),
  ingredients: Yup.array(
    Yup.object().shape({
      product: Yup.string(),
      qty: Yup.number(),
      loss: Yup.number(),
      price: Yup.number(),
      unit: Yup.string(),
      recipe: Yup.string(),
      type: Yup.string(),
      lossPrice: Yup.number(),
      totalPrice: Yup.number(),
      id: Yup.number().nullable(),
      productUnitPrice: Yup.number().nullable(),
    })
  ).min(1, 'Se requiere agregar al menos un ingrediente'),
  type: Yup.string().nullable(),
  productBaseId: Yup.string().nullable(),
});

export const subRecipeValidationSchema: Yup.SchemaOf<RecipeFormValues> =
  Yup.object({
    id: Yup.string(),
    _id: Yup.string(),
    accountId: Yup.string(),
    foodId: Yup.string().nullable(),
    accompanimentId: Yup.string().nullable(),
    foodType: Yup.string().nullable(),
    name: Yup.string().required('Este campo es requerido'),
    ingredients: Yup.array(
      Yup.object().shape({
        product: Yup.string(),
        qty: Yup.number(),
        loss: Yup.number(),
        price: Yup.number(),
        unit: Yup.string(),
        recipe: Yup.string(),
        type: Yup.string(),
        lossPrice: Yup.number(),
        totalPrice: Yup.number(),
        id: Yup.number().nullable(),
        productUnitPrice: Yup.number().nullable(),
      })
    ).min(1, 'Se requiere agregar al menos un ingrediente'),
    type: Yup.string().nullable(),
    productBaseId: Yup.string().nullable(),
  });

export const ingredientValidationSchema: Yup.SchemaOf<Ingredient> = Yup.object({
  unit: Yup.string().required('Este campo es requerido'),
  qty: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
      value <= 0 ? false : true
    )
    .required('Este campo es requerido'),
  loss: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .test(
      'Validación de Merma',
      'La Merma debe estar entre 0 y 100%',
      (value) => (value < 0 || value > 100 ? false : true)
    )
    .required('Este campo es requerido'),
  product: Yup.string().when('type', (type: string) => {
    if (type === IngredientType.PRODUCT) {
      return Yup.string().required('Este campo es requerido');
    } else {
      return Yup.string();
    }
  }),
  price: Yup.number(),
  // .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
  // .test('Validacion de price', 'Debe ser mayor a 0', (value) =>
  //   value <= 0 ? false : true
  // )
  // .required('Este campo es requerido'),
  recipe: Yup.string().when('type', (type: string) => {
    if (type === IngredientType.SUB_RECIPE) {
      return Yup.string().required('Este campo es requerido');
    } else {
      return Yup.string();
    }
  }),
  type: Yup.string().required('Este campo es requerido'),
  lossPrice: Yup.number(),
  // .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
  // .test('Validacion de lossPrice', 'Debe ser una cantidad positiva', (value) =>
  //   value < 0 ? false : true
  // )
  // .required('Este campo es requerido'),
  totalPrice: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .required('Este campo es requerido'),
  productUnitPrice: Yup.number().nullable(),
  id: Yup.number().nullable(),
});

export const RecipeInitialValues: Recipe = {
  _id: '',
  accountId: '',
  foodId: '',
  name: '',
  ingredients: [],
  createdAt: '',
  deletedAt: '',
  __v: 0,
  type: '',
  productBaseId: '',
  accompanimentId: '',
  updatedAt: '',
};
