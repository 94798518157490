import { CashRegisterMovementDao } from '../../../api/cashRegisterMovement/dao/cashRegisterMovement.dao';
import { CashRegisterMovementRepository } from '../../../api/cashRegisterMovement/repository/cashRegisterMovement.repository';
import {
  TypesActionsCashRegisterMovement,
  LIST_CASH_REGISTER_MOVEMENT,
  SHOW_CASH_REGISTER_MOVEMENT,
  UPDATE_CASH_REGISTER_MOVEMENT,
  LIST_CASH_MOVEMENTS,
  SHOW_CASH_MOVEMENT,
  RESET_CASH_REGISTER_MOVEMENTS_STATE,
} from './CashRegisterMovementTypes';
import {
  ResponseCashRegisterMovement,
  CashRegisterMovement,
  ResponseCashMovement,
  CashMovement,
} from '../../../types/cashRegisterMovement/CashRegisterMovement';
import { UserAuth } from '../../../types/users/User';

function listCashRegisterMovement(
  payload: ResponseCashRegisterMovement
): TypesActionsCashRegisterMovement {
  return {
    type: LIST_CASH_REGISTER_MOVEMENT,
    payload,
  };
}

function listCashMovements(
  payload: ResponseCashMovement
): TypesActionsCashRegisterMovement {
  return {
    type: LIST_CASH_MOVEMENTS,
    payload,
  };
}

function showCashRegisterMovement(
  payload: CashRegisterMovement
): TypesActionsCashRegisterMovement {
  return {
    type: SHOW_CASH_REGISTER_MOVEMENT,
    payload,
  };
}

function showCashMovement(
  payload: CashMovement
): TypesActionsCashRegisterMovement {
  return {
    type: SHOW_CASH_MOVEMENT,
    payload,
  };
}

function updateCashRegisterMovement(
  payload: CashRegisterMovement
): TypesActionsCashRegisterMovement {
  return {
    type: UPDATE_CASH_REGISTER_MOVEMENT,
    payload,
  };
}

export function resetCashRegisterMovementsState(): TypesActionsCashRegisterMovement {
  return {
    type: RESET_CASH_REGISTER_MOVEMENTS_STATE,
  };
}

export function listCashRegisterMovementAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await CashRegisterMovementDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listCashRegisterMovement(response.data));
  };
}

export function listCashMovementsAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await CashRegisterMovementDao.listCashMovements(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listCashMovements(response.data));
  };
}

export function showCashRegisterMovementAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await CashRegisterMovementDao.show(id, accountId);
      dispatch(showCashRegisterMovement(response.data));
    } catch (request) {
      alert(JSON.parse(request.response).message);
      callback();
    }
  };
}

export function createCashRegisterMovementAsync(
  form: object,
  callbackSuccess: (id: string) => void,
  callbackError: (message?: string) => void
) {
  return async function (dispatch: Function) {
    try {
      const response = await CashRegisterMovementRepository.create(form);
      callbackSuccess(response.data._id);
      dispatch(showCashRegisterMovement(response.data));
    } catch ({ request }) {
      let message = 'Ha ocurrido un error';
      const aux = `${JSON.parse(request.response).message}`;
      if (aux && aux === 'cash Register Movement opened already exist') {
        message = 'Existe una apertura de caja sin cerrar';
      }
      callbackError(message);
    }
  };
}

export function createMovementAsync(
  form: object,
  callbackSuccess: (id: string) => void,
  callbackError: (message?: string) => void
) {
  return async function (dispatch: Function) {
    try {
      const response = await CashRegisterMovementRepository.create(form);
      callbackSuccess(response.data._id);
      dispatch(showCashRegisterMovement(response.data));
    } catch ({ request }) {
      let message = 'Ha ocurrido un error';
      const aux = `${JSON.parse(request.response).message}`;
      if (aux && aux === 'cash Register Movement opened already exist') {
        message = 'Existe una apertura de caja sin cerrar';
      }
      callbackError(message);
    }
  };
}

export function createCashMovementAsync(
  form: object,
  callbackSuccess: () => void,
  callbackError: () => void
) {
  return async function (dispatch: Function) {
    try {
      const response = await CashRegisterMovementRepository.createCashMovement(
        form
      );
      callbackSuccess();
      // dispatch(showCashMovement(response.data));
    } catch ({ request }) {
      callbackError();
    }
  };
}

export function updateCashRegisterMovementAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await CashRegisterMovementRepository.update(form);

      dispatch(updateCashRegisterMovement(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export async function getCashRegisterMovementExcel(
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return await CashRegisterMovementDao.getCashRegisterMovementExcel(
    accountId,
    search,
    filter
  );
}
