import {
  TypesActionsInventory,
  LIST_INVENTORY,
  SHOW_INVENTORY,
  UPDATE_INVENTORY,
  DELETE_INVENTORY,
  LIST_INVENTORY2,
  LIST_INVENTORY_MOVEMENTS,
  RESET_INVENTORY_STATE,
} from '../../actions/inventory/InventoryTypes';
import { InventoryState } from '../../models/inventory/InventoryState';
import { generalResponse } from '../../../types/common/interfaces';
import { InventoryInitialValues } from '../../../types/inventory/interfaces';

const initialState: InventoryState = {
  inventories: { ...generalResponse, totalAmount: 0 },
  inventories2: { ...generalResponse, totalAmount: 0 },
  inventory: InventoryInitialValues,
  inventoryMovements: generalResponse,
};

export default function inventoryReducer(
  state = initialState,
  action: TypesActionsInventory
) {
  switch (action.type) {
    case LIST_INVENTORY_MOVEMENTS: {
      const inventoryMovements = action.payload;
      return {
        ...state,
        inventoryMovements,
      };
    }
    case LIST_INVENTORY: {
      const inventories = action.payload;
      return {
        ...state,
        inventories,
      };
    }
    case LIST_INVENTORY2: {
      const inventories2 = action.payload;
      return {
        ...state,
        inventories2,
      };
    }
    case SHOW_INVENTORY: {
      const inventory = action.payload;
      return {
        ...state,
        inventory,
      };
    }
    case UPDATE_INVENTORY: {
      const inventory = action.payload;
      const index = state.inventories.docs.findIndex(
        (todo) => todo._id === inventory._id
      );
      const newArray = [...state.inventories.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.inventories;
      const inventories = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        inventories,
      };
    }
    case DELETE_INVENTORY: {
      const id = action.payload;
      return {
        ...state,
        inventories: {
          docs: [...state.inventories.docs.filter((e) => e._id !== id)],
        },
      };
    }
    case RESET_INVENTORY_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
