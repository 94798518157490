import { StyleSheet } from 'react-native';

import generalStyles from './generalStyles';
import {
  primaryButton,
  primaryBlue,
  warningColor,
  boldGrayText,
  primaryButtonHover,
  normalWhite,
  grayButton1,
  tabButtonLightGray,
  grayText1,
  greenButton1,
  primaryOrange,
} from './variables';

const baseStyles = StyleSheet.create({
  baseButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 10,
    borderRadius: 8,
    width: '100%',
    alignItems: 'center',
    minWidth: 10,
  },
});

const buttonStyles = StyleSheet.create({
  orangeButton: {
    backgroundColor: primaryButton,
    color: normalWhite,
    ...baseStyles.baseButton,
  },
  redButton: {
    backgroundColor: 'red',
    color: normalWhite,
    ...baseStyles.baseButton,
  },

  blueButton: {
    backgroundColor: primaryBlue,
    ...baseStyles.baseButton,
  },
  yellowButton: {
    backgroundColor: warningColor,
    ...baseStyles.baseButton,
  },
  grayButton: {
    backgroundColor: grayButton1,
    ...baseStyles.baseButton,
    color: normalWhite,
  },
  greenButton: {
    backgroundColor: greenButton1,
    ...baseStyles.baseButton,
    color: normalWhite,
  },
  baseButton: {
    ...baseStyles.baseButton,
    color: normalWhite,
  },
  buttonWhiteText: {
    fontSize: 14,
    color: normalWhite,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  vectorIconWhite: {
    fontSize: 20,
    color: normalWhite,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  iconWhite: {
    width: 20,
    height: 20,
    tintColor: normalWhite,
  },
  containerSpaceBetween: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: 'space-between',
  },
  containerFlexEnd: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: 'flex-end',
  },
  containerSpaceEventy: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 12,
    paddingHorizontal: 16,
    justifyContent: 'space-evenly',
  },
  btnContainer: {
    marginHorizontal: 10,
    marginTop: 20,
    zIndex: 1,
    elevation: 1,
  },
  border: {
    borderBottomColor: boldGrayText,
    borderBottomWidth: 0.5,
    marginBottom: 5,
  },
  backButton: {
    backgroundColor: primaryButtonHover,
  },
  backText: {
    color: normalWhite,
    fontSize: 16,
  },
  title: {
    flex: 1,
    color: boldGrayText,
    fontSize: 20,
    fontWeight: 'bold',
  },
  navigateButton: {
    backgroundColor: primaryButton,
    borderRadius: 8, // Bordes redondeados
    paddingVertical: 10,
    paddingHorizontal: 16,
    alignItems: 'center',
    marginHorizontal: 10, // Centra horizontalmente el contenido
    marginTop: 5,
    marginBottom: 5,
  },
  navigateText: {
    color: normalWhite,
    fontSize: 16,
  },
  circleButtonWhite60x60: {
    flex: 1,
    width: 60,
    height: 60,
    minWidth: 60,
    minHeight: 60,
    maxWidth: 60,
    maxHeight: 60,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 100,
    backgroundColor: normalWhite,
    margin: 10,
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
  },
  nextScreenButton: {
    height: '100%',
    backgroundColor: primaryButton,
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextScreenIcon: {
    fontSize: 30,
    color: normalWhite,
  },
  tabButton: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRightColor: grayText1,
    borderRightWidth: 1,
    borderTopColor: grayText1,
    borderTopWidth: 1,
    borderTopRightRadius: 16,
    backgroundColor: tabButtonLightGray,
    height: 40,
    minHeight: 40,
  },
  moduleButton: {
    padding: 20,
    margin: 10,
    borderRadius: 16,
    width: 120,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  moduleText: {
    color: normalWhite, // Color del texto
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 4, // Ajusta el espaciado entre el icono y el texto
  },
  icon: {
    width: 50,
    height: 50,
    tintColor: normalWhite, // Tint color para personalizar el color del icono
    ...generalStyles.boxShadow,
  },
  tabsSection: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 20,
    height: 50,
    minHeight: 50,
    maxHeight: 50,
  },
  primaryButtonBackgroundColor: {
    backgroundColor: primaryButton,
  },
  primaryOrangeBackgroundColor: {
    backgroundColor: primaryOrange,
  },
});

export default buttonStyles;
