import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/ApiConfig';
import { Setting } from '../../../types/settings/Setting';

export const SettingsDao = {
  list: (accountId: string): Promise<AxiosResponse<Setting[]>> => {
    return axiosIntance.get(`/settings/list?account=${accountId}`);
  },
};
