import * as Yup from 'yup';

import { Food } from '../foods/Food';
import { ResponseInventory } from '../inventory/Inventory';
import { UserAuth } from '../users/User';
import { Decrease, ResponseDecrease } from './Decrease';
import { DecreaseType } from '../../utils/enums';

export interface DecreaseMainProps {
  user: UserAuth;
  inventories: ResponseInventory;
  getProducts: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  decreases: ResponseDecrease;
  listDecrease: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deleteDecrease: (
    form: {
      id: string;
      accountId: string;
    },
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  foods: Food[];
  getFoods: (
    accountId: string,
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
}

export interface CreateDecreaseProps {
  user: UserAuth;
  inventories: ResponseInventory;
  getProducts: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  createDecrease: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  foods: Food[];
  getFoods: (
    accountId: string,
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
}

export interface EditDecreaseProps {
  user: UserAuth;
  inventories: ResponseInventory;
  getProducts: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  updateDecrease: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  foods: Food[];
  getFoods: (
    accountId: string,
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
  decrease: Decrease;
  showDecrease: (
    id: string,
    accountId: string,
    callback: Function
  ) => Promise<void>;
}

export const DecreaseInitialValues: Decrease = {
  _id: '',
  accountId: '',
  productId: '',
  foodId: '',
  name: '',
  date: '',
  unit: '',
  qty: null,
  estimateAmount: null,
  observations: '',
  cause: '',
  createdAt: '',
  deletedAt: '',
  __v: 0,
};

export interface DecreaseFormValues {
  date: string;
  name: string;
  accountId?: string;
  foodId?: string;
  unit?: string;
  qty: number;
  cause: string;
  estimateAmount: number;
  productId: string;
  observations?: string;
  type: string;
  _id?: string;
  id?: string;
}

export const initialValues: DecreaseFormValues = {
  date: '',
  name: '',
  accountId: '',
  foodId: '',
  unit: '',
  qty: 1,
  cause: '',
  estimateAmount: 0,
  productId: '',
  observations: '',
  type: DecreaseType.FOOD,
};

export const validationSchema: Yup.SchemaOf<DecreaseFormValues> = Yup.object({
  date: Yup.string().required('Este campo es requerido'),
  name: Yup.string().required('Este campo es requerido'),
  productId: Yup.string().when('type', (type: string) => {
    if (type === DecreaseType.PRODUCT) {
      return Yup.string().required('Este campo es requerido');
    } else {
      return Yup.string();
    }
  }),
  foodId: Yup.string().when('type', (type: string) => {
    if (type === DecreaseType.FOOD) {
      return Yup.string().required('Este campo es requerido');
    } else {
      return Yup.string();
    }
  }),
  unit: Yup.string().when('type', (type: string) => {
    if (type === DecreaseType.PRODUCT) {
      return Yup.string().required('Este campo es requerido');
    } else {
      return Yup.string();
    }
  }),
  qty: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
      value <= 0 ? false : true
    )
    .required('Este campo es requerido'),
  cause: Yup.string().required('Este campo es requerido'),
  estimateAmount: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
      value <= 0 ? false : true
    )
    .required('Este campo es requerido'),
  accountId: Yup.string(),
  observations: Yup.string(),
  type: Yup.string().required('Este campo es requerido'),
  id: Yup.string(),
  _id: Yup.string(),
});
