import React, { useState } from 'react';
import { Text, View, TouchableOpacity, Platform } from 'react-native';
// import { Modal } from 'react-native';
import Modal from 'react-native-modal';
import buttonStyles from '../stylesheets/buttonStyles';

interface CustomAlertProps {
  visible: boolean;
  title: string;
  message: string;
  onAccept?: () => void;
  onCancel?: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  visible,
  title,
  message,
  onAccept,
  onCancel,
}) => {
  const handleAccept = () => {
    if (onAccept) {
      onAccept();
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  if (Platform.OS === 'web') {
    return (
      <Modal
        // transparent={true}
        // animationType="fade"
        // visible={visible}
        isVisible={visible}
        // onRequestClose={() => {}}
      >
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <View
            style={{ padding: 10, borderRadius: 10, backgroundColor: 'white' }}
          >
            {title && (
              <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{title}</Text>
            )}
            <Text>{message}</Text>
            <TouchableOpacity
              style={buttonStyles.orangeButton}
              onPress={handleAccept}
            >
              <Text style={{ color: '#FFF' }}>Aceptar</Text>
            </TouchableOpacity>
            {onCancel ? (
              <TouchableOpacity
                style={buttonStyles.yellowButton}
                onPress={handleCancel}
              >
                <Text style={{ color: '#FFF' }}>Cancelar</Text>
              </TouchableOpacity>
            ) : (
              null
            )}
          </View>
        </View>
      </Modal>
    );
  } else {
    return (
      <Modal
        // transparent={true}
        // animationType="fade"
        // visible={visible}
        isVisible={visible}
        // onRequestClose={onCancel}
      >
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <View
            style={{ backgroundColor: 'white', padding: 20, borderRadius: 10 }}
          >
            {title && (
              <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{title}</Text>
            )}
            <Text>{message}</Text>
            <View>
              <TouchableOpacity
                style={buttonStyles.orangeButton}
                onPress={handleAccept}
              >
                <Text style={{ color: '#FFF' }}>Aceptar</Text>
              </TouchableOpacity>
            </View>
            {onCancel && (
              <View>
                <TouchableOpacity
                  style={buttonStyles.yellowButton}
                  onPress={handleCancel}
                >
                  <Text style={{ color: '#FFF' }}>Cancelar</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </Modal>
    );
  }
};

export default CustomAlert;
