import { ResponsePayroll } from '../../../types/payroll/Payroll';
import { PayrollDao } from '../../../api/payroll/dao/payroll.dao';
import {
  TypesActionsPayroll,
  LIST_PAYROLLS,
  RESET_PAYROLLS_STATE,
} from './PayrollTypes';
import { Alert } from 'react-native';

function listPayrolls(payload: ResponsePayroll): TypesActionsPayroll {
  return {
    type: LIST_PAYROLLS,
    payload,
  };
}

export function resetPayrollsState(): TypesActionsPayroll {
  return {
    type: RESET_PAYROLLS_STATE,
  };
}

export function listPayrollsAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await PayrollDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listPayrolls(response.data));
  };
}

export async function getPayrollExcel(
  accountId: string,
  search: string | null,
  filter: string | null
) {
  try {
    return await PayrollDao.getExcel(accountId, search, filter);
  } catch ({ request }) {
    Alert.alert('Error', `${JSON.parse(request.response).message}`, [
      {
        text: 'OK',
        style: 'cancel',
        onPress: () => {},
      },
    ]);
  }
}
