import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import store from './store';
import { resetAccountingPurchaseState } from './actions/accountingPurchase/AccountingPurchaseActions';
import { resetAccountsState } from './actions/accounts/AccountsActions';
import { resetCashRegisterState } from './actions/cashRegister/CashRegisterActions';
import { resetCashRegisterMovementsState } from './actions/cashRegisterMovement/CashRegisterMovementActions';
import { resetDashboardState } from './actions/dashboard/DashboardActions';
import { resetDecreasesState } from './actions/decrease/DecreaseActions';
import { resetFoodCategoriesState } from './actions/foodCategories/FoodCategoriesActions';
import { resetFoodComplementsState } from './actions/foodComplements/FoodComplementsActions';
import { resetFoodsState } from './actions/foods/FoodsActions';
import { resetInventoryState } from './actions/inventory/InventoryActions';
import { resetInvoicesState } from './actions/invoice/InvoiceActions';
import { resetOrdersState } from './actions/orders/OrdersActions';
import { resetPayrollsState } from './actions/payroll/PayrollActions';
import { resetProvidersState } from './actions/provider/ProviderActions';
import { resetPurchaseState } from './actions/purchase/PurchaseActions';
import { resetRecipesState } from './actions/recipe/RecipeActions';
import { resetSalesState } from './actions/sales/SalesActions';
import { resetTablesState } from './actions/tables/TablesActions';
import { resetUsersState } from './actions/users/UsersActions';
import { clearUserAuth } from './actions/users/UsersActions';
import { resetWarehousesState } from './actions/warehouse/WarehouseActions';

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface Props {
  dispatch: Dispatch<AnyAction>;
  accountingPurchases?: boolean;
  accounts?: boolean;
  cashRegisters?: boolean;
  cashRegisterMovements?: boolean;
  dashboard?: boolean;
  decreases?: boolean;
  foodCategories?: boolean;
  foodComplements?: boolean;
  foods?: boolean;
  inventories?: boolean;
  invoices?: boolean;
  orders?: boolean;
  payrolls?: boolean;
  providers?: boolean;
  purchases?: boolean;
  recipes?: boolean;
  sales?: boolean;
  tables?: boolean;
  users?: boolean;
  userAuth?: boolean;
  warehouses?: boolean;
}

export const resetStoreStates = ({
  dispatch,
  accountingPurchases = true,
  accounts = true,
  cashRegisters = true,
  cashRegisterMovements = true,
  dashboard = true,
  decreases = true,
  foodCategories = true,
  foodComplements = true,
  foods = true,
  inventories = true,
  invoices = true,
  orders = true,
  payrolls = true,
  providers = true,
  purchases = true,
  recipes = true,
  sales = true,
  tables = true,
  users = true,
  userAuth = true,
  warehouses = true,
}: Props) => {
  accountingPurchases && dispatch(resetAccountingPurchaseState());
  accounts && dispatch(resetAccountsState());
  cashRegisters && dispatch(resetCashRegisterState());
  cashRegisterMovements && dispatch(resetCashRegisterMovementsState());
  dashboard && dispatch(resetDashboardState());
  decreases && dispatch(resetDecreasesState());
  foodCategories && dispatch(resetFoodCategoriesState());
  foodComplements && dispatch(resetFoodComplementsState());
  foods && dispatch(resetFoodsState());
  inventories && dispatch(resetInventoryState());
  invoices && dispatch(resetInvoicesState());
  orders && dispatch(resetOrdersState());
  payrolls && dispatch(resetPayrollsState());
  providers && dispatch(resetProvidersState());
  purchases && dispatch(resetPurchaseState());
  recipes && dispatch(resetRecipesState());
  sales && dispatch(resetSalesState());
  tables && dispatch(resetTablesState());
  users && dispatch(resetUsersState());
  userAuth && dispatch(clearUserAuth());
  warehouses && dispatch(resetWarehousesState());
};
