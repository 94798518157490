import {
  TypesActionsSale,
  LIST_SALE,
  SHOW_SALE,
  UPDATE_SALE,
  DELETE_SALE,
  // GET_SALES,
  RESET_SALES_STATE,
} from '../../actions/sales/SalesTypes';
import { SalesState } from '../../../store/models/sales/SalesState';
import { SaleInitialValue } from '../../../types/sales/interface';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: SalesState = {
  sales: { ...generalResponse, totalAmount: 0 },
  sale: SaleInitialValue,
  // salesList: generalResponse,
};

export default function salesReducer(
  state = initialState,
  action: TypesActionsSale
) {
  switch (action.type) {
    case LIST_SALE: {
      const sales = action.payload;
      return {
        ...state,
        sales,
      };
    }
    // case GET_SALES: {
    //   const salesList = action.payload;
    //   return {
    //     ...state,
    //     salesList,
    //   };
    // }
    case SHOW_SALE: {
      const sale = action.payload;
      return {
        ...state,
        sale,
      };
    }
    case UPDATE_SALE: {
      const sale = action.payload;
      const index = state.sales.docs.findIndex((todo) => todo._id === sale._id);
      const newArray = [...state.sales.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.sales;
      const sales = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        sales,
      };
    }
    case DELETE_SALE: {
      const id = action.payload;
      return {
        ...state,
        sales: {
          ...state.sales,
          docs: [...state.sales.docs.filter((e) => e._id !== id)],
        },
      };
    }
    case RESET_SALES_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
