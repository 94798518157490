import * as Yup from 'yup';

import {
  ResponseInventory,
  Inventory,
  ResponseInventoryMovements,
} from './Inventory';
import { UserAuth } from '../users/User';
import { ResponseWarehouse, Warehouse } from '../warehouse/Warehouse';
import { AccountSimplifiedPlanValues } from '../accounts/interfaces';
import { WarehouseInitialValues } from '../warehouse/interfaces';
import { financialFormat } from '../../utils/helpers';
// import { containComma } from '../../utils/helpers';

export interface InventoryMainProps {
  inventories: ResponseInventory;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deleteInventory: (
    form: {
      id: string;
      accountId: string;
    },
    callbackSuccess?: Function,
    callbackError?: (message?: string) => void
  ) => Promise<void>;
  user: UserAuth;
}

export interface InventoryMovementsProps {
  user: UserAuth;
  inventoryMovements: ResponseInventoryMovements;
  listMovements: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string,
    productId: string[]
  ) => Promise<void>;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  inventories: ResponseInventory;
}

export interface InventoryCreateProps {
  createInventory: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
  warehouses: ResponseWarehouse;
  listWarehouse: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface InventoryMovementCreateProps {
  user: UserAuth;
  inventories: ResponseInventory;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  createInventoryMovement: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
}

export interface InventoryEditProps {
  inventory: Inventory;
  showInventory: (
    id: string,
    accountId: string,
    callback: Function
  ) => Promise<void>;
  updateInventory: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
  warehouses: ResponseWarehouse;
  listWarehouse: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface InventoryFormValues {
  id?: string;
  _id: string;
  accountId: string;
  warehouseId: string;
  name: string;
  qty: number;
  unit: string;
  description: string;
  category: string;
  type: string;
  status: string;
  unitPrice: number;
  purchasePrice: number;
  minExistence: number;
  expireDate: string;
}
export const InventoryProductInitialValues: InventoryFormValues = {
  id: '',
  _id: '',
  name: '',
  accountId: '',
  warehouseId: '',
  qty: 0,
  unit: '',
  description: '',
  category: '',
  type: '',
  status: 'activo',
  unitPrice: 0,
  purchasePrice: 0,
  minExistence: 0,
  expireDate: '',
};

export const InventoryValidationSchema: Yup.SchemaOf<InventoryFormValues> =
  Yup.object({
    id: Yup.string(),
    _id: Yup.string(),
    name: Yup.string().required('Este campo es requerido'),
    accountId: Yup.string(),
    warehouseId: Yup.string().required('Este campo es requerido'),
    qty: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      // .test(
      //   'Validacion de decimales',
      //   'Debe sustituir la coma (,) por punto (.)',
      //   (value) => !containComma(value)
      // )
      .required('Este campo es requerido'),
    unit: Yup.string().required('Este campo es requerido'),
    description: Yup.string().required('Este campo es requerido'),
    category: Yup.string().required('Este campo es requerido'),
    status: Yup.string().required('Este campo es requerido'),
    type: Yup.string().required('Este campo es requerido'),
    unitPrice: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .required('Este campo es requerido'),
    purchasePrice: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .required('Este campo es requerido'),
    minExistence: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    expireDate: Yup.string().required('Este campo es requerido'),
  });

export const InventoryInitialValues: Inventory = {
  id: '',
  _id: '',
  name: '',
  accountId: AccountSimplifiedPlanValues,
  warehouseId: WarehouseInitialValues,
  qty: 0,
  unit: '',
  description: '',
  category: '',
  type: '',
  status: 'activo',
  unitPrice: 0,
  purchasePrice: 0,
  minExistence: 0,
  expireDate: '',
  createdAt: '',
  deletedAt: '',
  __v: 0,
  totalIn: 0,
  totalOut: 0,
};

export interface InventoryMovementForm {
  accountId: string;
  productId: string;
  type: string;
  movementType: string;
  schemaName: string;
  observations: string;
  qty: number;
  amount?: string;
}

export const initialValues: InventoryMovementForm = {
  accountId: '',
  productId: '',
  type: '',
  movementType: '',
  schemaName: '',
  observations: '',
  qty: 1,
  amount: financialFormat({ amount: 0 }),
};

export const validationSchema: Yup.SchemaOf<InventoryMovementForm> = Yup.object(
  {
    accountId: Yup.string(),
    productId: Yup.string().required('Este campo es requerido'),
    type: Yup.string().required('Este campo es requerido'),
    movementType: Yup.string().required('Este campo es requerido'),
    schemaName: Yup.string().required('Este campo es requerido'),
    observations: Yup.string(),
    amount: Yup.string(),
    qty: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
  }
);
