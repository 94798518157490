import { StyleSheet } from 'react-native';

import { errorRed, normalWhite } from './variables';

const generalStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: normalWhite,
    borderRadius: 8,
    padding: 16,
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 4,
    margin: 20,
    // width: '100%',
    height: '100%',
  },
  subContainer: {
    // flex: 1,
    width: '100%',
    // height: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  errorMessage: {
    fontSize: 14,
    fontWeight: 'bold',
    color: errorRed,
  },
  message: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  normalMessage: {
    marginTop: 5,
    fontSize: 16,
    fontWeight: 'normal',
  },
  logo: {
    width: 200,
    height: 120,
    resizeMode: 'contain',
  },
  boxShadow: {
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 4,
  },
  form: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 25,
    flex: 1,
  },
  line: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lineButton: {
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    marginBottom: 5,
  },
});

export default generalStyles;
