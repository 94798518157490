import {
  TypesActionsTables,
  LIST_TABLES,
  ADD_TABLE,
  DELETE_TABLE,
  UPDATE_TABLE,
  GET_TABLES,
  RESET_TABLES_STATE,
} from '../../actions/tables/TablesTypes';
import { TablesState } from '../../models/tables/TablesState';
import { AccountSimplifiedPlanValues } from '../../../types/accounts/interfaces';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: TablesState = {
  tables: generalResponse,
  table: {
    _id: '',
    name: '',
    isEnable: true,
    account: AccountSimplifiedPlanValues,
    createdAt: '',
    deletedAt: '',
    __v: 0,
  },
  tablesArray: [],
};

export default function tablesReducer(
  state = initialState,
  action: TypesActionsTables
) {
  switch (action.type) {
    case LIST_TABLES: {
      const tables = action.payload;
      return {
        ...state,
        tables: tables,
      };
    }
    case DELETE_TABLE: {
      const table = action.payload;
      return {
        ...state,
        tables: {
          docs: [...state.tables.docs.filter((e) => e._id !== table)],
        },
      };
    }
    case UPDATE_TABLE: {
      const table = action.payload;
      const index = state.tables.docs.findIndex(
        (todo) => todo._id === table._id
      );
      const newArray: any[] = [...state.tables.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.tables;
      const tables = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state, //copying the orignal state
        tables,
      };
    }
    case ADD_TABLE: {
      const table = action.payload;
      return {
        ...state,
        table,
      };
    }
    case GET_TABLES: {
      const tablesArray = action.payload;
      return {
        ...state,
        tablesArray,
      };
    }
    case RESET_TABLES_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
