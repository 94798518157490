import * as Yup from 'yup';
import { Account } from '../accounts/Accounts';
import { UserAuth } from '../users/User';
import { Food, ResponseFoodsProps } from '../foods/Food';
import { ComplementsStatus } from '../../utils/enums';

export interface FoodsFormValues {
  id?: string;
  _id?: string;
  name: string;
  description: string;
  status: string;
  price: number;
  account: string;
  discountPercent: number;
  foodCategoryId: Array<string>;
  complements: Array<string>;
  image: string;
  showOnMenu?: string;
}

export interface ManagementFoodsCreateProps {
  user: UserAuth;
  account: Account;
  createFood: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  getAccount: (accountId: string, callback: Function) => Promise<void>;
}

export interface ManagementFoodsEditProps {
  user: UserAuth;
  food: Food;
  updateFood: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  getFood: (id: string, callback: Function) => Promise<void>;
}

export interface ManagementFoodsProps {
  user: UserAuth;
  account: Account;
  foods: ResponseFoodsProps;
  getFoods: (
    page: number,
    limit: number,
    accountId: string,
    search: string | null
  ) => Promise<void>;
  deleteFood: (
    accountId: string,
    id: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  getAccount: (accountId: string, callback: Function) => Promise<void>;
}

export const initialValues: FoodsFormValues = {
  id: '',
  _id: '',
  name: '',
  description: '',
  status: ComplementsStatus.ACTIVE,
  price: 0,
  account: '',
  discountPercent: 0,
  foodCategoryId: [],
  complements: [],
  image: '',
  showOnMenu: 'true',
};

export const validationSchema: Yup.SchemaOf<FoodsFormValues> = Yup.object({
  _id: Yup.string(),
  id: Yup.string(),
  name: Yup.string().required('Este campo es requerido'),
  description: Yup.string().required('Este campo es requerido'),
  status: Yup.string().required('Este campo es requerido'),
  price: Yup.number()
    .positive('La cantidad debe ser positiva')
    .typeError('Este campo debe ser un número')
    .required('Este campo es requerido'),
  account: Yup.string().required('Este campo es requerido'),
  discountPercent: Yup.number()
    .test(
      'Evaluando descuento',
      'El porcentaje de descuento debe estar entre 0 y 100%',
      (price) => (price < 0 || price > 100 ? false : true)
    )
    .typeError('Este campo debe ser un número')
    .required('Este campo es requerido'),
  foodCategoryId: Yup.array().required('Este campo es requerido'),
  complements: Yup.array(),
  image: Yup.string(),
  showOnMenu: Yup.string().default('true'),
});

export const foodInitialState: Food = {
  _id: '',
  account: '',
  complements: [],
  createdAt: '',
  createdBy: '',
  deletedAt: null,
  description: '',
  discountPercent: 0,
  foodCategoryId: [],
  image: '',
  name: '',
  price: 0,
  status: '',
  timeStampId: 0,
  quantity: 0,
  quantityToPay: 0,
  serviceFee: 0,
  servicePercentage: 0,
  vatFee: 0,
  vatPercentage: 0,
  orderStatus: '',
  otherFee: 0,
  otherPercentage: 0,
  discountFee: 0,
  otherPercentageToPay: 0,
  servicePercentageToPay: 0,
  vatPercentageToPay: 0,
  otherFeeToPay: 0,
  vatFeeToPay: 0,
  discountFeeToPay: 0,
  subTotalToPay: 0,
  serviceFeeToPay: 0,
  totalToPay: 0,
};
