import { FormikProps } from 'formik';
import * as Yup from 'yup';

import { ResponseProvider, Provider } from './Provider';
import { UserAuth } from '../users/User';
import { ResponseInventory } from '../inventory/Inventory';
import { AccountSimplifiedPlanValues } from '../accounts/interfaces';

export interface ProviderMainProps {
  providers: ResponseProvider;
  listProvider: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deleteProvider: (
    form: {
      id: string;
      accountId: string;
    },
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
}

export interface ProviderCreateProps {
  createProvider: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
  inventories: ResponseInventory;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface ProviderEditProps {
  provider: Provider;
  showProvider: (
    id: string,
    accountId: string,
    callback: Function
  ) => Promise<void>;
  updateProvider: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  user: UserAuth;
  inventories: ResponseInventory;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface ProviderFormValues {
  id?: string;
  _id: string;
  name: string;
  accountId?: string;
  phone: string;
  documentId: string;
  typeDocument: string;
  email: string;
  observations: string;
  status: string;
  productId: Array<string>;
}
export const ProviderInitialValues: ProviderFormValues = {
  id: '',
  _id: '',
  accountId: '',
  name: '',
  phone: '',
  documentId: '',
  typeDocument: '',
  email: '',
  observations: '',
  status: 'activo',
  productId: [],
};

export const ProviderValidationSchema: Yup.SchemaOf<ProviderFormValues> =
  Yup.object({
    id: Yup.string(),
    _id: Yup.string(),
    name: Yup.string().required('Este campo es requerido'),
    phone: Yup.string().required('Este campo es requerido'),
    observations: Yup.string(),
    documentId: Yup.string().required('Este campo es requerido'),
    typeDocument: Yup.string().required('Este campo es requerido'),
    email: Yup.string().required('Este campo es requerido'),
    status: Yup.string().required('Este campo es requerido'),
    productId: Yup.array(Yup.string()),
    accountId: Yup.string(),
  });

export const ProviderInitialState: Provider = {
  _id: '',
  id: '',
  accountId: AccountSimplifiedPlanValues,
  name: '',
  phone: '',
  documentId: '',
  typeDocument: '',
  email: '',
  observations: '',
  status: '',
  productId: [],
  createdAt: '',
  deletedAt: '',
  __v: 0,
};
