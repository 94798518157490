import {
  TypesActionsPurchase,
  LIST_PURCHASE,
  SHOW_PURCHASE,
  UPDATE_PURCHASE,
  DELETE_PURCHASE,
  GET_PURCHASES,
  RESET_PURCHASES_STATE,
} from '../../actions/purchase/PurchaseTypes';
import { PurchaseState } from '../../models/purchase/PurchaseState';
import { PurchaseInitialValues } from '../../../types/shopping/interface';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: PurchaseState = {
  purchases: { ...generalResponse, totalAmount: 0 },
  purchase: PurchaseInitialValues,
  purchasesList: { ...generalResponse, totalAmount: 0 },
};

export default function purchaseReducer(
  state = initialState,
  action: TypesActionsPurchase
) {
  switch (action.type) {
    case LIST_PURCHASE: {
      const purchases = action.payload;
      return {
        ...state,
        purchases,
      };
    }
    case GET_PURCHASES: {
      const purchasesList = action.payload;
      return {
        ...state,
        purchasesList,
      };
    }
    case SHOW_PURCHASE: {
      const purchase = action.payload;
      return {
        ...state,
        purchase,
      };
    }
    case UPDATE_PURCHASE: {
      const purchase = action.payload;
      const index = state.purchases.docs.findIndex(
        (todo) => todo._id === purchase._id
      );
      const newArray = [...state.purchases.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.purchases;
      const purchases = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        purchases,
      };
    }
    case DELETE_PURCHASE: {
      const id = action.payload;
      return {
        ...state,
        purchases: {
          docs: [...state.purchases.docs.filter((e) => e._id !== id)],
        },
      };
    }
    case RESET_PURCHASES_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
