import { StyleSheet, Text, View } from 'react-native';
import React from 'react';

import { appBackgroundGray } from '../../stylesheets/variables';

const Footer = () => {
  return (
    <View style={styles.container}>
      <Text>{`Versión del Software: ${process.env.REACT_APP_VERSION}`}</Text>
    </View>
  );
};

export default Footer;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: appBackgroundGray,
    paddingHorizontal: 20,
  },
});
