import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import {
  Warehouse,
  ResponseWarehouse,
} from '../../../types/warehouse/Warehouse';

export const warehouserDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseWarehouse>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = 0;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(
      `/warehouse/list?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  show: (
    id: string,
    accountId: string
  ): Promise<AxiosResponse<Warehouse>> => {
    return axiosIntance.get(`/warehouse/${accountId}/${id}`);
  },
};
