import { axiosIntance } from '../../../config/ApiConfig';

const config = {
  headers: { 'content-type': 'multipart/form-data' }
}

export const FoodsRepository = {
  delete: (data: any) => {
    return axiosIntance.delete(`/food`, { data: { id: data._id, ...data } });
  },
  create: (form: object) => {
    return axiosIntance.post(`/food`, form, config);
  },
  update: (form: object) => {
    return axiosIntance.put(`/food`, form, config);
  }
};
