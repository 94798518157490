import { PurchaseDao } from '../../../api/purchase/dao/purchase.dao';
import { PurchaseRepository } from '../../../api/purchase/repository/purchase.repository';
import {
  TypesActionsPurchase,
  LIST_PURCHASE,
  SHOW_PURCHASE,
  UPDATE_PURCHASE,
  DELETE_PURCHASE,
  GET_PURCHASES,
  RESET_PURCHASES_STATE,
} from './PurchaseTypes';
import { ResponsePurchase, Purchase } from '../../../types/shopping/Shopping';

function listPurchase(payload: ResponsePurchase): TypesActionsPurchase {
  return {
    type: LIST_PURCHASE,
    payload,
  };
}

function showPurchase(payload: Purchase): TypesActionsPurchase {
  return {
    type: SHOW_PURCHASE,
    payload,
  };
}

function updatePurchase(payload: Purchase): TypesActionsPurchase {
  return {
    type: UPDATE_PURCHASE,
    payload,
  };
}

function deletePurchase(payload: string): TypesActionsPurchase {
  return {
    type: DELETE_PURCHASE,
    payload,
  };
}

function getPurchases(payload: ResponsePurchase): TypesActionsPurchase {
  return {
    type: GET_PURCHASES,
    payload,
  };
}

export function resetPurchaseState(): TypesActionsPurchase {
  return {
    type: RESET_PURCHASES_STATE,
  };
}

export function listPurchaseAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await PurchaseDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listPurchase(response.data));
  };
}

export function showPurchaseAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await PurchaseDao.show(id, accountId);
      dispatch(showPurchase(response.data));
    } catch (error) {
      console.log(error);
      callback();
    }
  };
}

export function createPurchaseAsync(
  form: object,
  callbackSuccess: (purchase: Purchase) => void,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await PurchaseRepository.create(form);
      callbackSuccess(response.data);
      dispatch(showPurchase(response.data));
    } catch (error) {
      callbackError();
      console.log(error);
    }
  };
}

export function updatePurchaseAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await PurchaseRepository.update(form);

      dispatch(updatePurchase(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function deletePurchaseAsync(
  form: {
    id: string;
    accountId: string;
  },
  type: string,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      await PurchaseRepository.delete(form);
      const response = await PurchaseDao.list(
        form.accountId,
        null,
        null,
        null,
        `type=${type}`
      );
      dispatch(deletePurchase(form.id));
      dispatch(listPurchase(response.data));
      callbackSuccess();
    } catch (error) {
      console.log(error);
      callbackError();
    }
  };
}

export function getPurchasesAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await PurchaseDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(getPurchases(response.data));
  };
}

export async function getDetailPdf(accountId: string, id: string) {
  return await PurchaseDao.getDetailPdf(accountId, id);
}
