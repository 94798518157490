import React, { FC, useState, useRef } from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Text,
  Platform,
  Image
} from 'react-native';
import Modal from 'react-native-modal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert, Linking } from 'react-native';
import { useFormik, FormikHelpers } from 'formik';
import Constants from 'expo-constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { Auth, UserLogin } from '../types/auth/Auth';
import { AccountSimplifiedPlan } from '../types/accounts/Accounts';
import { UsersRole } from '../utils/enums';
import { Loader } from '../components/SuspenseLoader';
import {
  CheckUserAsync,
  SignInAsync,
  updateUserAuthStorage,
} from '../store/actions/users/UsersActions';
import { useAppDispatch } from '../store/reduxCustomHooks';
import DropDownPicker from 'react-native-dropdown-picker';
import { getInvoiceStatusAsync } from '../store/actions/invoice/InvoiceActions';
import CustomAlert from '../components/CustomAlert';
import {
  LoginFormValues,
  initialValues,
  validationSchema,
} from '../types/auth/interface';
import { SettingsDao } from '../api/settings/dao/settings.dao';
import { getUserData } from '../utils/helpers';
import generalStyles from '../stylesheets/generalStyles';
import { normalWhite } from '../stylesheets/variables';

interface LoginScreenProps {
  navigation: any;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}
const LoginScreen: FC<LoginScreenProps> = ({
  navigation,
  setAuthenticated,
}) => {
  const captchaKey = Constants.manifest.extra.REACT_APP_RECAPTCHA_API_KEY;
  const captchaDomain = Constants.manifest.extra.REACT_APP_RECAPTCHA_DOMAIN;
  const captcha = useRef(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalErrorVisible, setModalErrorVisible] = useState(false);
  const [user, setUser] = useState<UserLogin>(null);
  const [listAccounts, setListAccounts] = useState<AccountSimplifiedPlan[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validateCaptcha, setValidateCaptcha] = useState(true);
  const dispatch = useAppDispatch();

  //handler web
  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setValidateCaptcha(true);
    }
  };

  const handleError = (error: unknown) => {
    setValidateCaptcha(false);
  };

  const getSettings = async () => {
    const user = await getUserData();
    const response = await SettingsDao.list(user.selectedAccount);
    const settings = response.data;
    if (settings.length) {
      const setting = settings[0];
      user.settings = setting;
    } else {
      user.settings = null;
    }
    dispatch(updateUserAuthStorage(user));
  };

  const handleSubmit = (
    values: LoginFormValues,
    { resetForm }: FormikHelpers<LoginFormValues>
  ) => {
    if (Platform.OS === 'web') {
      if (captcha.current.getValue()) {
        setValidateCaptcha(true);
        handleLogin(values);
      } else {
        setValidateCaptcha(false);
      }
    } else {
      handleLogin(values);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const openPrivacyPolicy = () => {
    Linking.openURL('https://mozzolife.com/privacy-policy/');
  };

  const openTermsAndConditions = () => {
    Linking.openURL('https://mozzolife.com/terminos-condiciones/');
  };

  const openRecoveryPassowrd = () => {
    Linking.openURL('https://store.mozzolife.com/passwordRecovery');
  };

  const handleSelectChange = (value: string) => {
    setSelectedAccount(value);
  };
  const redirect = () => {
    // AsyncStorage.setItem('user', JSON.stringify(user)).then(() => {
    //   setAuthenticated(true);
    // });
    setAuthenticated(true);
  };
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const toggleModalError = () => {
    setModalErrorVisible(!isModalVisible);
  };

  const checkAccountForPayment = async (user: UserLogin) => {
    const data = await getInvoiceStatusAsync(user.selectedAccount);
    user.allowedAccount = data.data;
    // console.log(data.data.allowed)
    await AsyncStorage.setItem('user', JSON.stringify(user));
  };

  const checkUserForLogin = (data: Auth) => {
    const user = data.user;
    formik.resetForm();
    setUser(user);
    if (
      data.user.role !== UsersRole.SUPERADMIN &&
      data.user.role !== UsersRole.ADMIN
    ) {
      if (data.user.account.length > 1) {
        setListAccounts(
          data.account?.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            } else if (a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          })
        );
        setModalVisible(true);
      } else if (data.user.account.length === 1) {
        singleAccountSignIn(data.user, data.account);
      } else {
        Alert.alert(
          'Error',
          'Acceso no permitido',
          [
            {
              text: 'OK',
              style: 'cancel',
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
      }
    } else {
      Alert.alert(
        'Error',
        'Acceso no permitido',
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
    }
  };

  const singleAccountSignIn = async (
    user: UserLogin,
    accountList: AccountSimplifiedPlan[]
  ) => {
    user.selectedAccount = user.account[0];
    user.accountList = accountList;
    setUser(user);
    await AsyncStorage.setItem('user', JSON.stringify(user));
    SignInAsync(
      user,
      redirect,
      async () => await checkAccountForPayment(user),
      dispatch,
      getSettings
    );
  };

  const handleSignIn = async () => {
    user.selectedAccount = selectedAccount;
    user.accountList = listAccounts;
    SignInAsync(
      user,
      redirect,
      async () => {
        await checkAccountForPayment(user);
      },
      dispatch,
      getSettings
    );
  };

  const handleLogin = (values: LoginFormValues) => {
    CheckUserAsync(
      {
        email: values.email,
        password: values.password,
      },
      (data: Auth) => {
        formik.resetForm();
        checkUserForLogin(data);
      },
      (message: string) => {
        setShowError(true);
        setErrorMessage(message);
      }
    );
  };

  return (
    <View style={styles.container}>
      <Image
       source={require('../../assets/logo-mozzo.png')}
        style={styles.logo}
      />
      <Text style={styles.title}>Inicio de Sesión</Text>
      <TextInput
        placeholder="Correo electrónico"
        style={styles.input}
        onChangeText={formik.handleChange('email')}
        onBlur={formik.handleBlur('email')}
        value={formik.values.email}
      />
      {formik.touched.email && formik.errors.email && (
        <Text style={generalStyles.errorMessage}>{formik.errors.email}</Text>
      )}
      <TextInput
        placeholder="Contraseña"
        style={styles.input}
        secureTextEntry
        onChangeText={formik.handleChange('password')}
        onBlur={formik.handleBlur('password')}
        value={formik.values.password}
      />
      {formik.touched.password && formik.errors.password && (
        <Text style={generalStyles.errorMessage}>{formik.errors.password}</Text>
      )}

      {Platform.OS === 'web' && (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 16,
            marginBottom: 8,
          }}
        >
          <ReCAPTCHA
            ref={captcha}
            sitekey={captchaKey}
            onChange={handleCaptcha}
          />
          {!validateCaptcha && (
            <Text style={generalStyles.errorMessage}>
              Por favor valide el captcha para iniciar sesión
            </Text>
          )}
        </View>
      )}

      {/* {Platform.OS !== 'web' && (
        <View>
          <GoogleRecaptcha
            ref={recaptchaRef}
            baseUrl={captchaDomain}
            onError={handleError}
            onVerify={handleVerify}
            siteKey={captchaKey}
          />
          {!validateCaptcha && (
            <Text style={generalStyles.errorMessage}>
              Por favor valide el captcha para iniciar sesión
            </Text>
          )}
        </View>
      )} */}

      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          if (Platform.OS === 'web') {
            formik.handleSubmit();
          } else {
            formik.handleSubmit();
            // if (!formik.values.email || !formik.values.password) {
            //   formik.handleSubmit();
            // } else {
            //   recaptchaRef.current?.open();
            // }
          }
        }}
      >
        <Text style={styles.buttonText}>Iniciar Sesión</Text>
      </TouchableOpacity>

      <View style={styles.containerDisclaimer}>
        <Text
          style={[styles.link, styles.linkBlue]}
          onPress={openRecoveryPassowrd}
        >
          Olvidó la contraseña?
        </Text>
      </View>
      <View style={styles.containerDisclaimer}>
        <Text style={styles.disclaimer}>
          Copyright © Mozzolife.com 2023.
          {'\n'}
          <Text>
            <Text style={styles.link} onPress={openPrivacyPolicy}>
              Aviso de privacidad
            </Text>
            -
            <Text style={styles.link} onPress={openTermsAndConditions}>
              Términos y condiciones de uso
            </Text>
          </Text>
        </Text>
      </View>

      <Modal isVisible={isModalVisible}>
        <View style={styles.modal}>
          <View style={styles.modalContent}>
            <Text style={styles.selectTitle}>
              Seleccione una cuenta para ingresar:
            </Text>
            {listAccounts && (
              <View style={{ zIndex: 10, elevation: 10 }}>
                <DropDownPicker
                  placeholder="Seleccione una cuenta"
                  multiple={false}
                  setOpen={setOpen}
                  open={open}
                  value={selectedAccount}
                  items={listAccounts.map((item) => ({
                    label: item.name,
                    value: item._id, // Puedes usar un campo único como valor
                  }))}
                  setValue={setSelectedAccount}
                  setItems={setListAccounts}
                  style={{ zIndex: 10, elevation: 10 }}
                  listItemContainerStyle={{ zIndex: 10, elevation: 10 }}
                />
              </View>
            )}
            <View style={styles.btnContainer}>
              <TouchableOpacity
                style={styles.button}
                onPress={() => handleSignIn()}
              >
                <Text style={styles.buttonText}>Aceptar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.btnYellow]}
                onPress={() => toggleModal()}
              >
                <Text style={styles.buttonText}>Cerrar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      {/* <Modal isVisible={isModalErrorVisible}>
        <View style={styles.modal}>
          <View
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <Text style={styles.title}>
              Nombre de usuario o contraseña incorrectos!
            </Text>
          </View>
          <Button title="Cerrar" onPress={toggleModalError} />
        </View>
      </Modal> */}
      <CustomAlert
        title="Error"
        message={errorMessage}
        onAccept={() => {
          setShowError(false);
          setTimeout(() => {
            setErrorMessage('');
          }, 500);
        }}
        visible={showError}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  input: {
    width: '40%',
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#F60C0C',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
    marginHorizontal: 10,
    marginVertical: 10,
    zIndex: 1,
    elevation: 1,
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  logo: {
    width: 250,
    height: 150,
    resizeMode: 'contain',
  },
  modal: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginHorizontal: '10%',
    alignContent: 'center',
    elevation: 6,
  },
  modalContent: {
    backgroundColor: '#FFF',
    elevation: 5,
    width: 400,
    height: 300,
    padding: 20,
    borderRadius: 10,
  },
  btnYellow: {
    backgroundColor: '#FFAF39',
  },
  picker: {
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginVertical: 10,
  },
  pickerItem: {
    fontSize: 16, // Estilo de los elementos del Picker
  },
  modalContanier: {
    width: '100%',
  },
  btnContainer: {
    marginHorizontal: 10,
    marginTop: 20,
    zIndex: 1,
    elevation: 1,
  },
  selectTitle: {
    marginBottom: 5,
  },
  containerDisclaimer: {
    padding: 20,
  },
  disclaimer: {
    fontSize: 14,
    textAlign: 'center',
  },
  link: {
    textDecorationLine: 'underline',
  },
  linkBlue: {
    color: '#196fd2',
  },
  imageLoader: {
    backgroundColor: normalWhite,
  },
});

export default LoginScreen;
