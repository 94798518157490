import { Dashboard } from '../../../types/dashboard/DashBoard';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE';

interface ActionGetDashboard {
  type: typeof GET_DASHBOARD;
  payload: Dashboard;
}

interface ActionResetDashboardState {
  type: typeof RESET_DASHBOARD_STATE;
}

export type TypesActionsDashboard =
  | ActionGetDashboard
  | ActionResetDashboardState;
