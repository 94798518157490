import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import { Recipe } from '../../../types/recipe/Recipe';

export const RecipeRepository = {
  create: (form: object): Promise<AxiosResponse<Recipe>> => {
    return axiosIntance.post('/recipe', form);
  },
  update: (form: object): Promise<AxiosResponse<Recipe>> => {
    return axiosIntance.put('/recipe', form);
  },
  delete: (form: object): Promise<AxiosResponse<{ sucess: boolean }>> => {
    return axiosIntance.delete('/recipe', { data: form });
  },
};
