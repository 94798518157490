import React, { useState, useEffect, Suspense } from 'react';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PaperProvider } from 'react-native-paper';
import { Provider } from 'react-redux';
import { AutocompleteDropdownContextProvider } from 'react-native-autocomplete-dropdown';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Platform } from 'react-native';

import store from './src/store/store';
import { UserAuth } from './src/types/users/User';
import { UserAuthInitialValues } from './src/types/users/interfaces';

import RoutesProtected from './routesProtected';
import LoginScreen from './src/screens/LoginScreen';

const App = () => {
  let page = '/';

  if (Platform.OS === 'web') {
    page = window.location.pathname + window.location.search;
  }

  ReactGA.initialize('G-BHC2HBMDHH');
  ReactGA.send({
    hitType: 'pageview',
    page,
    title: 'POS',
  });

  Sentry.init({
    dsn: 'https://c95e2a14cde304dca10c85ae670c8eb4@o4507465517891584.ingest.us.sentry.io/4507470976712704', // Reemplaza con tu DSN de Sentry
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'pos.mozzolife.com',
          'api.mozzolife.com',
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
  });

  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<UserAuth>(UserAuthInitialValues);
  AsyncStorage.getItem('user').then((data) => {
    if (data !== null) {
      setAuthenticated(true);
    }
  });

  useEffect(() => {
    AsyncStorage.getItem('user').then((data) => {
      if (data !== null) {
        setUser(JSON.parse(data));
      }
    });
  }, [authenticated]);

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <AutocompleteDropdownContextProvider>
        <PaperProvider>
          <Provider store={store}>
            <NavigationContainer>
              {authenticated ? (
                <RoutesProtected
                  setAuthenticated={setAuthenticated}
                  user={user}
                  authenticated={authenticated}
                />
              ) : (
                <LoginScreen
                  setAuthenticated={setAuthenticated}
                  navigation={useNavigation}
                />
              )}
            </NavigationContainer>
          </Provider>
        </PaperProvider>
      </AutocompleteDropdownContextProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
