import {
  TypesActionsFoods,
  LIST_FOODS,
  DELETE_FOOD,
  ADD_FOOD,
  UPDATE_FOOD,
  GET_FOODS,
  RESET_FOODS_STATE,
} from '../../actions/foods/FoodsTypes';
import { FoodsState } from '../../models/foods/FoodsState';
import { generalResponse } from '../../../types/common/interfaces';
import { foodInitialState } from '../../../types/foods/interfaces';

const initialState: FoodsState = {
  foods: generalResponse,
  food: foodInitialState,
  foodsArray: [],
};

export default function foodsReducer(
  state = initialState,
  action: TypesActionsFoods
) {
  switch (action.type) {
    case LIST_FOODS: {
      const foods = action.payload;
      return {
        ...state,
        foods: foods,
      };
    }
    case DELETE_FOOD: {
      const food = action.payload;
      return {
        ...state,
        foods: {
          docs: [...state.foods.docs.filter((e) => e._id !== food)],
        },
      };
    }
    case UPDATE_FOOD: {
      const food = action.payload;
      const index = state.foods.docs.findIndex((todo) => todo._id === food._id);
      const newArray = [...state.foods.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.foods;
      const foods = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state, //copying the orignal state
        foods,
      };
    }
    case ADD_FOOD: {
      const food = action.payload;
      return {
        ...state,
        food,
      };
    }
    case GET_FOODS: {
      const foodsArray = action.payload;
      return {
        ...state,
        foodsArray,
      };
    }
    case RESET_FOODS_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
