import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import { Sale, ResponseSales } from '../../../types/sales/Sales';

export const SalesDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseSales>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    if (!page) {
      page = 0;
    }
    if (!limit) {
      limit = 5;
    }
    return axiosIntance.get(
      `/sales/list?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  show: (id: string, accountId: string): Promise<AxiosResponse<Sale>> => {
    return axiosIntance.get(`/sales/${accountId}/${id}`);
  },
};
