import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import {
  Invoice,
  InvoiceStatus,
  ResponseInvoiceProps
} from '../../../types/payments/Payment';

export const InvoiceDao = {
  getInvoiceStatus: (id: string): Promise<AxiosResponse<InvoiceStatus>> => {
    return axiosIntance.get(`/accounts/status/${id}`);
  },
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseInvoiceProps>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    if (!page) {
      page = process.env.REACT_APP_LIST_PAGE_DEFAULT;
    }
    if (!limit) {
      limit = process.env.REACT_APP_LIST_ROW_DEFAULT;
    }
    return axiosIntance.get(
      `/invoice?page=${page}&limit=${limit}&account=${accountId}${query}${filter}`
    );
  },
  show: (id: string): Promise<AxiosResponse<Invoice>> => {
    return axiosIntance.get(`invoice/${id}`);
  },
  getPdf: (id: string): Promise<AxiosResponse<Blob>> => {
    return axiosIntance.get(`invoice/${id}/pdf`, {
      responseType: 'blob', // important
    });
  }
};
