import { AccountingPurchase } from './accountingPurchase';
import { AccountSimplifiedPlanValues } from '../accounts/interfaces';
import { ProviderInitialState } from '../provider/interfaces';

export const AccountingPurchaseValues: AccountingPurchase = {
  _id: '',
  accountId: AccountSimplifiedPlanValues,
  code: 0,
  providerId: ProviderInitialState,
  amount: 0,
  observations: '',
  date: '',
  reference: '',
  products: [],
  createdAt: '',
  deletedAt: '',
  __v: 0,
  type: '',
  status: '',
};
