import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/ApiConfig';
import { Decrease, ResponseDecrease } from '../../../types/decrease/Decrease';

export const DecreaseRepository = {
  create: (form: object): Promise<AxiosResponse<Decrease>> => {
    return axiosIntance.post('/decrease', form);
  },
  update: (form: object): Promise<AxiosResponse<Decrease>> => {
    return axiosIntance.put('/decrease', form);
  },
  delete: (data: {
    id: string;
    accountId: string;
  }): Promise<AxiosResponse<Decrease>> => {
    return axiosIntance.delete('/decrease', { data });
  },
};
