import { Table, ResponseTablesProps } from '../../../types/tables/Tables';

export const LIST_TABLES = 'LIST_TABLES';
export const ADD_TABLE = 'ADD_TABLE';
export const DELETE_TABLE = 'DELETE_TABLE';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const GET_TABLES = 'GET_TABLES';
export const RESET_TABLES_STATE = 'RESET_TABLES_STATE';

interface ActionListTables {
  type: typeof LIST_TABLES;
  payload: ResponseTablesProps;
}

interface ActionAddTables {
  type: typeof ADD_TABLE;
  payload: Table;
}

interface ActionDeleteTables {
  type: typeof DELETE_TABLE;
  payload: string;
}
interface ActionUpdateTables {
  type: typeof UPDATE_TABLE;
  payload: Table;
}

interface ActionGetTables {
  type: typeof GET_TABLES;
  payload: Table[];
}

interface ActionResetTablesState {
  type: typeof RESET_TABLES_STATE;
}

export type TypesActionsTables =
  | ActionListTables
  | ActionAddTables
  | ActionDeleteTables
  | ActionUpdateTables
  | ActionGetTables
  | ActionResetTablesState;
