import { axiosIntance } from '../../../config/ApiConfig';

export const FoodComplementsRepository = {
  delete: (data: any) => {
    return axiosIntance.delete(`/foodComplements`, { data: { id: data._id, ...data } });
  },
  create: (form: object) => {
    return axiosIntance.post(`/foodComplements`, form);
  },
  update: (form: object) => {
    return axiosIntance.put(`/foodComplements`, form);
  }
};
