import { AxiosResponse } from 'axios';
import {
  GET_DASHBOARD,
  RESET_DASHBOARD_STATE,
  TypesActionsDashboard,
} from './DashboardTypes';
import { Dashboard } from '../../../types/dashboard/DashBoard';
import { DashboardDao } from '../../..//api/dashboard/dashboard.dao';
import { Alert } from 'react-native';

export function getDashboard(payload: Dashboard): TypesActionsDashboard {
  return {
    type: GET_DASHBOARD,
    payload,
  };
}

export function resetDashboardState(): TypesActionsDashboard {
  return {
    type: RESET_DASHBOARD_STATE,
  };
}

export function getDashboardAsync(accountId: string) {
  return async function (dispacth: Function) {
    try {
      const dashboard: AxiosResponse<Dashboard> = await DashboardDao.get(
        accountId
      );
      dispacth(getDashboard(dashboard.data));
    } catch ({ request }) {
      Alert.alert(
        'Error',
        `${JSON.parse(request.response).message}`,
        [
          {
            text: 'OK',
            style: 'cancel',
            onPress: () => {},
          },
        ],
        { cancelable: false }
      );
    }
  };
}
