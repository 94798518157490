import * as Yup from 'yup';

import { UserAuth } from '../users/User';
import { ResponseInventory } from '../inventory/Inventory';
import { ResponseProvider } from '../provider/Provider';
import {
  Purchase,
  ResponsePurchase,
  Product,
  PurchaseOrderId,
} from './Shopping';
import { AccountSimplifiedPlanValues } from '../accounts/interfaces';
import { ProviderInitialState } from '../provider/interfaces';

export interface ShoppingScreenProps {
  user: UserAuth;
  purchases: ResponsePurchase;
  listPurchase: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deletePurchaseAsync: (
    form: {
      id: string;
      accountId: string;
    },
    type: string,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
}

export interface CreateShoppingProps {
  user: UserAuth;
  inventories: ResponseInventory;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  createPurchase: (
    form: object,
    callbackSuccess: (purchase: Purchase) => void,
    callbackError: Function
  ) => Promise<void>;
  providers: ResponseProvider;
  listProvider: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  purchases: ResponsePurchase;
  getPurchases: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  updatePurchase: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
}

export interface EditShoppingProps {
  user: UserAuth;
  inventories: ResponseInventory;
  listInventory: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  updatePurchase: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  getPurchase: (
    id: string,
    accountId: string,
    callback: Function
  ) => Promise<void>;
  purchase: Purchase;
  providers: ResponseProvider;
  listProvider: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  purchases: ResponsePurchase;
  getPurchases: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface ProductFormValues extends Product {}

export interface ShoppingFormValues {
  accountId?: string;
  purchaseOrderId?: string;
  amount: number;
  observations: string;
  products: ProductFormValues[];
  id?: string;
  _id?: string;
  providerId?: string;
  date: string;
  type?: string;
  reference?: string;
  status: string;
}

export const initialValues: ShoppingFormValues = {
  accountId: '',
  purchaseOrderId: '',
  amount: 0,
  observations: '',
  products: [],
  providerId: '',
  date: '',
  type: '',
  reference: '',
  id: '',
  _id: '',
  status: '',
};

export const validationSchema: Yup.SchemaOf<ShoppingFormValues> = Yup.object({
  accountId: Yup.string(),
  purchaseOrderId: Yup.string().nullable(),
  amount: Yup.number()
    .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
    .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
      value <= 0 ? false : true
    )
    .required('Este campo es requerido'),
  observations: Yup.string(),
  products: Yup.array(
    Yup.object().shape({
      productId: Yup.string(),
      name: Yup.string(),
      qty: Yup.number(),
      unit: Yup.string(),
      unitPrice: Yup.number(),
      total: Yup.number(),
      category: Yup.string(),
      notes: Yup.string(),
    })
  ).min(1, 'Se requiere algregar al menos un producto'),
  id: Yup.string(),
  providerId: Yup.string().nullable(),
  date: Yup.string().required('Este campo es requerido'),
  reference: Yup.string(),
  type: Yup.string(),
  _id: Yup.string(),
  status: Yup.string().when('_id', (_id: string) => {
    if (_id) {
      return Yup.string().required('Este campo es requerido');
    } else {
      return Yup.string();
    }
  }),
});

export const initialValuesProduct: ProductFormValues = {
  productId: '',
  name: '',
  qty: 1,
  unit: '',
  unitPrice: 0,
  total: 0,
  category: '',
  notes: '',
};

export const validationSchemaProduct: Yup.SchemaOf<ProductFormValues> =
  Yup.object({
    productId: Yup.string().required('Este campo es requerido'),
    name: Yup.string().required('Este campo es requerido'),
    qty: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    unit: Yup.string().required('Este campo es requerido'),
    unitPrice: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    total: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    category: Yup.string(),
    notes: Yup.string(),
  });

export const PurchaseOrderIdInitialValues: PurchaseOrderId = {
  _id: '',
  accountId: '',
  purchaseOrderId: null,
  code: 0,
  providerId: '',
  amount: 0,
  observations: '',
  date: '',
  status: '',
  reference: '',
  type: '',
  products: [],
  createdAt: '',
  deletedAt: '',
  __v: 0,
};

export const PurchaseInitialValues: Purchase = {
  _id: '',
  accountId: AccountSimplifiedPlanValues,
  purchaseOrderId: PurchaseOrderIdInitialValues,
  type: '',
  reference: '',
  code: 0,
  amount: 0,
  date: '',
  status: '',
  providerId: ProviderInitialState,
  observations: '',
  products: [],
  createdAt: '',
  deletedAt: '',
  __v: 0,
};
